export class Layer {
  _onyx = null
  _layerConfig = null
  _isActive = false

  constructor(onyx, layerConfig) {
    console.assert(onyx !== null, 'onyx is null')
    console.assert(layerConfig !== null, 'layerConfig is null')
    this._onyx = onyx
    this._layerConfig = layerConfig
    this._add()
  }

  toggle(show) {
    const visibility = show ? 'visible' : 'none'
    this._layerConfig.layout.visibility = visibility
    this._onyx.toggleLayer(this._onyx.MAIN_VIEWPORT_ID, this._layerConfig.id, visibility)
    this._isActive = show
  }

  update() {
    this._remove()
    this._add()
  }

  isActive() {
    return this._isActive
  }

  _remove() {
    console.debug('Removing layer ', this._layerConfig.id)
    this._onyx.removeLayer(this._onyx.MAIN_VIEWPORT_ID, this._layerConfig.id)
  }

  _add() {
    console.debug('Adding layer ', this._layerConfig.id)
    this._onyx.addLayer(this._onyx.MAIN_VIEWPORT_ID, JSON.stringify(this._layerConfig))
  }
}
