import * as bootstrap from 'bootstrap'

import { retrieveToken, setToken } from '../../utilities/token.js'
import appService from '../../services/app-service.js'

export let onXUserJwt = ''

const environment = 'production'

const authenticationUrl = `https://api.${environment}.onxmaps.com/v1/tokens`
const subscriptionUrl = `https://api.${environment}.onxmaps.com/v2/subscriptions`

function isMembershipAuthorized(membership) {
  // array of account levels allowed to access the app
  const allowedMembershipLevels = ['elite', 'employee']

  if (allowedMembershipLevels.includes(membership.membership_level)) {
    return true
  }

  // Allow elite trial users to access
  if (membership.membership_level === 'trial') {
    return (
      membership.product_code.includes('nationwide') ||
      membership.product_code.includes('elite')
    )
  }

  return false
}

export function initialize() {
  onXUserJwt = retrieveToken()

  if (onXUserJwt) {
    // check subscription
    fetchSubscription(onXUserJwt)
      .then((data) => {
        // if not in the list of allowed account types then show upgrade dialog
        if (isMembershipAuthorized(data[appService.getApp()])) {
          onLoginSuccess()
          return true
        } else {
          onNeedUpgrade()
          return true
        }
      })
      .catch(() => {
        // show regular sign in if something with token fails
        bootstrap.Modal.getOrCreateInstance(
          document.getElementById('modal-login-form')
        ).show()
      })
  } else {
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('modal-login-form')
    ).show()
  }
}

function onLoginSuccess() {
  document.getElementById('login-wait-spinner').classList.add('d-none')
  document.getElementById('modal-sign-in-error').innerHTML = ''
  bootstrap.Modal.getOrCreateInstance(
    document.getElementById('modal-login-form')
  ).hide()
  document.dispatchEvent(
    new CustomEvent('on-login-success', {
      detail: { onXUserJwt: onXUserJwt },
    })
  )
}

function onNeedUpgrade() {
  document.getElementById('login-wait-spinner').classList.add('d-none')
  document.getElementById('modal-sign-in-error').innerHTML = ''
  bootstrap.Modal.getOrCreateInstance(
    document.getElementById('modal-login-form')
  ).hide()
  document.dispatchEvent(new CustomEvent('on-need-upgrade'))
}

class LoginModalComponent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="modal fade" id="modal-login-form" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3><i class="fa fa-sign-in fa-3x"></i></h3>
              <h2 class="text-center">Sign In</h2>
            </div>
            <div id="modal-sign-in-error" class="alert alert-danger d-none"></div>
            <form>
              <fieldset>
                <div class="input-group" style="padding-bottom: 10px;">
                  <span class="input-group-text"><i class="fa fa-envelope color-blue"></i></span>
                  <input id="sign-in-email" name="email" placeholder="Email Address" class="form-control" type="email"
                    aria-label="email" aria-describedby="E-Mail" autocomplete="email">
                </div>
    
                <div class="input-group" style="padding-bottom: 10px;">
                  <span class="input-group-text"><i class="fa fa-lock color-blue"></i></span>
                  <input type="password" class="form-control" placeholder="Password" name="password" value=""
                    aria-label="password" aria-describedby="Password" autocomplete="current-password" id="sign-in-password">
                </div>
    
                <div class="d-grid gap-2" style="padding-bottom: 10px;">
                  <!-- Change this to a button or input when using this as a form -->
                  <a href="#" class="btn btn-hunt navbar-btn hidden-print" id="modal-login-login-button">
                    <span id="login-wait-spinner"
                      class="spinner-border spinner-border-sm d-none"></span>
                    Login
                  </a>
                </div>
    
                <div class="d-grid gap-2" style="padding-bottom: 10px;">
                  <a href="${this.getAttribute(
                    'sign-up-url'
                  )}" class="btn btn-light hidden-print">Create an Account</a>
                  <!--<a href="#" class="btn btn-light btn-sm" data-bs-dismiss="modal" data-bs-toggle="modal"
                    data-bs-target="#modal-sign-up-form">Create an account</a>-->
                </div>
    
                <div class="d-grid gap-2 text-center" style="padding-bottom: 10px;">
                  <small>
                    <a href="#" data-bs-dismiss="modal" id="modal-login-forgot-password-button" style="text-decoration:none;" data-bs-toggle="modal"
                      data-bs-target="#modal-forgot-password-form">
                      Forgot password
                    </a>
                  </small>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>`

    document
      .getElementById('modal-login-login-button')
      .addEventListener('click', this.login)
  }

  login() {
    const email = document.getElementById('sign-in-email').value
    const password = document.getElementById('sign-in-password').value

    document.getElementById('login-wait-spinner').classList.remove('d-none')
    document.getElementById('modal-sign-in-error').innerHTML = ''

    fetchToken(email, password)
      .then((token) => {
        fetchSubscription(token).then((data) => {
          // if not employee, hunt elite, or backcountry elite then show upgrade dialog
          if (isMembershipAuthorized(data[appService.getApp()])) {
            onLoginSuccess()
            return true
          } else {
            onNeedUpgrade()
            return true
          }
        })
      })
      .catch((error) => {
        document.getElementById('login-wait-spinner').classList.add('d-none')
        document.getElementById('modal-sign-in-error').innerHTML = error.message
        document
          .getElementById('modal-sign-in-error')
          .classList.remove('d-none')
      })
  }
}

customElements.define('login-modal-component', LoginModalComponent)

async function fetchToken(email, password) {
  const response = await fetch(authenticationUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: email, password: password }),
  })

  const data = await response.json()

  if ('errors' in data) {
    throw new Error(data.errors)
  }

  if ('token' in data) {
    setToken(data.token)
    onXUserJwt = data.token
  }

  return onXUserJwt
}

async function fetchSubscription(onXUserJwt) {
  const response = await fetch(subscriptionUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${onXUserJwt}`,
    },
  })

  const data = await response.json()

  if ('errors' in data) {
    throw new Error(data.errors)
  }

  return data
}
