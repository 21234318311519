class BaseMapToggleComponent extends HTMLElement {
  #prevLocation

  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
  
      <style>
  
      #basemap-toggle {
        position: absolute;
        bottom: 20px;
        right: 20px;
        border-radius: 10px;
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.75);
        outline: none;
        font-size: 20px;
        border: none;
        transition: background-color 250ms ease;
      }

      #basemap-toggle:hover {
        background-color: rgba(0, 0, 0, 1);
      }

      </style>
  
      <div>
        <button id="basemap-toggle">
          <i class="fa fa-map" id="location-icon"></i>
        </button>
      </div>
      `

    document
      .getElementById('basemap-toggle')
      .addEventListener('click', this.basemapToggle)
  }

  basemapToggle() {
    document.dispatchEvent(new CustomEvent('basemap-toggle'))
  }
}

customElements.define(
  'basemap-toggle-component',
  BaseMapToggleComponent
)
