import appService, { APP_HUNT } from '../../services/app-service.js'

const huntFeedbackUrl =
  'https://webmap.onxmaps.com/hunt/map/elite-features/feedback/terrain-x'

const BcFeedbackUrl = 'https://onxbackcountry.zendesk.com/hc/en-us/requests/new'
class SendFeedbackButtonComponent extends HTMLElement {
  constructor() {
    super()
  }
  getApp() {
    return appService.getApp()
  }
  getAppClass() {
    return appService.appClass()
  }
  getFeedbackUrl() {
    return this.getApp() === APP_HUNT ? huntFeedbackUrl : BcFeedbackUrl
  }
  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      #send-feedback-button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;
      }

      #send-feedback-button a i {
        margin-right: 5px;
    }
    </style>


    <div id="send-feedback-button">
        <a class="x-button primary small ${this.getAppClass()}" href="${this.getFeedbackUrl()}" target="_blank" role="button">
            <i class="fa fa-external-link"></i>
            Send Feedback
        </a>
    </div>
      `
  }
}

customElements.define(
  'send-feedback-button-component',
  SendFeedbackButtonComponent
)
