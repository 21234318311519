import * as bootstrap from 'bootstrap';

class SliderSwitchComponent extends HTMLElement {
  constructor() {
    super()

    // Attach a shadow root
    this._shadowRoot = this.attachShadow({ mode: 'open' })
    this._shadowRoot.innerHTML = String.raw`
      <style>
      .switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 24px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #65B604;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #65B604;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 24px;
      }

      .slider.round:before {
        border-radius: 50%;
      }

      </style>

      <label class="switch">
        <input type="checkbox" checked>
        <span class="slider round"></span>
      </label>
    `
  }

  connectedCallback() {
    const collapseElement = document.getElementById(
      this.getAttribute('collapse-element-id')
    )
    this.collapse = new bootstrap.Collapse(collapseElement, { toggle: false })
    this.collapse.show()

    const checkbox = this._shadowRoot.querySelector('input')
    checkbox.addEventListener('change', (event) => {
      if (event.currentTarget.checked === true) {
        this.collapse.show()
      } else {
        this.collapse.hide()
      }
      const myEvent = new CustomEvent('on-slider-change', {
        detail: event.currentTarget.checked,
      })
      this.dispatchEvent(myEvent)
    })
  }
}

customElements.define('slider-switch-component', SliderSwitchComponent)
