import * as bootstrap from 'bootstrap'

class ForgotPasswordModalComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="modal fade" id="modal-forgot-password-form" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3><i class="fa fa-lock fa-3x"></i></h3>
              <h2 class="text-center">Forgot Password?</h2>
    
              <div id="modal-forgot-password-error" class="alert alert-danger d-none"></div>
    
              <div class="input-group" style="padding-bottom: 10px;">
                <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                <input id="forgot-password-email" name="email" placeholder="email address" class="form-control"
                  type="email">
              </div>
    
              <div class="d-grid gap-2" style="padding-bottom: 10px;">
                <a href="#" class="btn btn-sm btn-hunt" id="send-forgot-password-email-button">
                  <span id="forgot-password-wait-spinner" class="spinner-border spinner-border-sm d-none" role="status"></span>
                  Reset Password
                </a>
              </div>
              <div class="d-grid gap-2" style="padding-bottom: 10px;">
                <a href="#" class="btn btn-light btn-sm" data-bs-dismiss="modal" data-bs-toggle="modal"
                  data-bs-target="#modal-login-form">Back</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal fade" id="modal-forgot-password-email-sent" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3><i class="fa fa-envelope fa-3x"></i></h3>
              <h2 class="text-center">Password reset email sent.</h2>
              <p>Check your email for the link.</p>
            </div>
          </div>
        </div>
      </div>
    </div>`

    document
      .getElementById('send-forgot-password-email-button')
      .addEventListener('click', this.sendForgotPasswordEmail)
  }

  sendForgotPasswordEmail() {
    document
      .getElementById('forgot-password-wait-spinner')
      .classList.remove('d-none')
    document.getElementById('modal-forgot-password-error').innerHTML = ''
    document
      .getElementById('modal-forgot-password-error')
      .classList.add('d-none')

    let email = document.getElementById('forgot-password-email').value.trim()

    // testing...
    setTimeout(() => {
      console.log(email)
      bootstrap.Modal.getOrCreateInstance(
        document.getElementById('modal-forgot-password-form')
      ).hide()
      bootstrap.Modal.getOrCreateInstance(
        document.getElementById('modal-forgot-password-email-sent')
      ).show()
      document
        .getElementById('forgot-password-wait-spinner')
        .classList.add('d-none')
    }, 1000)
    // end testing

    /*firebase.auth().sendPasswordResetEmail(email).then(function() {
        // Email sent.
        //$('#forgot-password-wait-spinner').addClass('hidden');
        //$('#modal-forgot-password-form').modal('hide');
        //$('#modal-forgot-password-email-sent').modal('show');
    }).catch(function(error) {
        // An error happened.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        //$('#forgot-password-wait-spinner').addClass('hidden');
        //$('#modal-forgot-password-error').removeClass('hidden');
        //$('#modal-forgot-password-error').html(errorMessage);
    });*/
  }
}

customElements.define(
  'forgot-password-modal-component',
  ForgotPasswordModalComponent
)
