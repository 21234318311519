import { OPACITY } from './IntersectLayer.js';
import { Layer } from './Layer.js';

export class ViewshedLayer extends Layer {
  constructor(onyx) {
    super(onyx, {
      id: 'viewshed',
      type: 'viewshed',
      source: 'elevation',
      layout: {
        'visibility': 'none',
        'viewshed-lon': 0,
        'viewshed-lat': 0,
        'viewshed-range': 6437.376,
        'viewshed-inverted': true,
      },
      paint: {
        'viewshed-tint': '#000',
        'viewshed-opacity': OPACITY,
        'viewshed-ring-tint': '#FF3300'
      },
    })
  }

  update(screenX, screenY) {
    console.assert(typeof screenX === 'number')
    console.assert(typeof screenY === 'number')
    const screenPoint = { x: screenX, y: screenY }
    const lle = this._onyx.viewportToLonLatElevation(this._onyx.MAIN_VIEWPORT_ID, screenPoint)
    this._layerConfig.layout['viewshed-lon'] = lle.lon
    this._layerConfig.layout['viewshed-lat'] = lle.lat
    super.update()
  }
}
