import Navigo from 'navigo';

let router

class AppComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
  
      <div
        id="app-container"
        style="width: 100%; height: 100%; position: absolute; background-color: lightgray;"
      >
        <div
        id="map-container"
        style="width: 100%; height: 100%; position: absolute"
        >
            <search-component></search-component>
            <canvas
                id="canvas"
                style="position: relative; width: 100%; height: 100%"
            ></canvas>
            <div id="router-view"></div>
            <small-tools-container-component></small-tools-container-component>
            <account-buttons-component
                sign-up-url="https://webmap.onxmaps.com/hunt/create-account?after_login=https%3A%2F%2Flabs.onxmaps.com%2F"
            ></account-buttons-component>
            <send-feedback-button-component></send-feedback-button-component>
            <zoom-control-component></zoom-control-component>
            <go-to-location-control-component></go-to-location-control-component>
            <basemap-toggle-component></basemap-toggle-component>
        </div>
      </div>
      `

    // initialize router
    router = new Navigo('/')
    router.on('/', () => {
      // automatically redirect to hunt
      // FUTURE add routing support for multiple apps
      router.navigate('/hunt')
    })

    /*** Hunt routes ***/
    router.on('/hunt', () => {
      // update router view with terrain tools component
      this.updateRouterView('terrain-tools-container')
    })

    router.on('/hunt/waypoint-create', () => {
      // update router view with waypoint create card componoent
      this.updateRouterView('waypoint-create-card')
    })

    router.on('/hunt/flyby', () => {
      // update router view with the flyby card component
      this.updateRouterView('flyby-card')
    })
    /*** end Hunt routes ***/

    /*** Backcountry routes ***/
    router.on('/backcountry', () => {
      // update router view with terrain tools component
      this.updateRouterView('terrain-tools-container')
    })

    router.on('/backcountry/waypoint-create', () => {
      // update router view with waypoint create card componoent
      this.updateRouterView('waypoint-create-card')
    })

    router.on('/backcountry/flyby', () => {
      // update router view with the flyby card component
      this.updateRouterView('flyby-card')
    })
    /*** end Backcountry routes ***/

    /*** Offroad routes ***/
    router.on('/offroad', () => {
      // update router view with terrain tools component
      this.updateRouterView('terrain-tools-container')
    })

    router.on('/offroad/waypoint-create', () => {
      // update router view with waypoint create card componoent
      this.updateRouterView('waypoint-create-card')
    })

    router.on('/offroad/flyby', () => {
      // update router view with the flyby card component
      this.updateRouterView('flyby-card')
    })
    /*** end Offroad routes ***/

    // Fall back to Hunt
    // FUTURE - Something still not quite right with this. Works for /badpath, but not /hunt/badpath
    // Haven't figured out the Navigo syntax for exact matching
    router.notFound(() => {
      router.navigate('/hunt')
    })
    router.resolve()
  }

  updateRouterView(view) {
    const routerView = document.getElementById('router-view')
    routerView.innerHTML = `<${view}-component></${view}-component>`
  }
}

customElements.define('app-component', AppComponent)

export default router
