import { Layer } from './Layer.js';

export const OPACITY = 0.6

export class IntersectLayer extends Layer {
  _intersectableLayers = []

  constructor(onyx) {
    super(onyx, {
      id: 'intersect$terrain',
      type: 'intersect',
      source: 'elevation',
      layout: {
        'visibility': 'none',
        'intersect-inverted': true,
      },
      paint: {
        'intersect-elevation-mask': [['range', 2000, 3000]],
        'intersect-slope-angle-mask': [['range', 20, 60]],
        'intersect-slope-aspect-mask': [['range', 0, 360]],
        'intersect-slope-aspect-minimum-angle': 0,
        'intersect-tint': '#000',
        'intersect-opacity': OPACITY,
      },
    })
  }

  register(intersectableLayer) {
    this._intersectableLayers.push(intersectableLayer)
  }

  toggle(show) {
    if (show) {
      this._intersectableLayers.forEach((layer) => layer.toggle(false))
    }
    super.toggle(show)
  }

  update(inverted) {
    if (inverted != null) {
      console.assert(typeof inverted === 'boolean')
      this._layerConfig.layout['intersect-inverted'] = inverted
      this._layerConfig.paint['intersect-tint'] = inverted ? "#000" : "#008080"
    }
    super.update()
  }
}
