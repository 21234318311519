import './slider-switch-component.js'
import './slope-angle-picker-component.js'
import * as bootstrap from 'bootstrap'

class ViewshedComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      #viewshed {
        margin-top: 10px;
        margin-left: 10px;
        position: relative; 
        width:355px; 
        background: rgba(0, 0, 0, 0.5); 
        z-index: 10;
        border-radius: 10px;
        font-size: 1.5rem;
        padding: 20px;
      }

      .float-right {
        position: absolute;
        right: 20px;
      }

    </style>

    <div id='viewshed'>
      <h5 style='color:white;padding-bottom:14px;'>
        <a href="#" style="text-decoration:none;color:white;" data-bs-toggle="tooltip" data-bs-placement="right" title="This tool allows you to visualize what can be seen from any point on the map. Once enabled, the viewshed will follow your cursor. You can fix the viewshed position in place by double clicking on the map.">
          Real-time Viewshed
          <i class="fa fa-question-circle" aria-hidden="true"></i>
        </a>
        <slider-switch-component id="viewshed-toggle" class="float-right" collapse-element-id="viewshed-body"></slider-switch-component>
      </h5>

      <div id='viewshed-body' class="collapse text-center">
        <i class="fa fa-binoculars text-light"></i>
      </div>
    </div> 
    `

    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    const slider = document.getElementById('viewshed-toggle')
    slider.addEventListener('on-slider-change', (event) => {
      // toggle viewshed...
      const myEvent = new CustomEvent('on-viewshed-toggled', {
        detail: event.detail,
      })
      document.dispatchEvent(myEvent)
    })
  }
}

customElements.define('viewshed-component', ViewshedComponent)
