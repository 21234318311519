class LogoutModal extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="modal fade" id="modal-logout-form" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3><i class="fa fa-sign-out fa-3x"></i></h3>
              <h2 class="text-center">Sign Out</h2>
            </div>
            <div class="d-grid gap-2" style="padding-bottom: 10px;">
              <a href="#" class="btn btn-lg btn-hunt btn-block" data-bs-dismiss="modal"
                id="modal-logout-sign-out-button">Sign Out</a>
            </div>
            <div class="d-grid gap-2" style="padding-bottom: 10px;">
              <a href="#" class="btn btn-md btn-light btn-block text-small" data-bs-dismiss="modal"
                id="modal-logout-not-yet-button">Not yet</a>
            </div>
          </div>
        </div>
      </div>
    </div>`

    document
      .getElementById('modal-logout-sign-out-button')
      .addEventListener('click', this.logout)
  }

  logout() {
    document.dispatchEvent(new CustomEvent('on-logout'))
  }
}

customElements.define('logout-modal-component', LogoutModal)
