import { IntersectableLayer } from './IntersectableLayer.js';

export class SlopeAngleLayer extends IntersectableLayer {
  _GRADIENT = []

  constructor(onyx, intersectLayer) {
    super(
      onyx,
      {
        id: 'slope$angle$shade',
        type: 'slope-angle',
        source: 'elevation',
        layout: {
          'visibility': 'none',
          'slope-angle-gradient': [
            'gradient',
            [
              0,  '#00000000',
              20, '#00000000',
              20, '#80FF01',
              25, '#80FF01',
              25, '#FFFF01',
              30, '#FFFF01',
              30, '#E69137',
              35, '#E69137',
              35, '#FE0000',
              40, '#FE0000',
              40, '#9A00FF',
              45, '#9A00FF',
              45, '#0000FE',
              90, '#0000FE',
            ],
          ],
        },
        paint: {
          'slope-angle-opacity': 0.5,
        },
      },
      intersectLayer
    )
  }

  update(start, end) {
    console.assert(typeof start === 'number')
    console.assert(typeof end === 'number')
    const ranges = [['range', start, end]]
    this._intersectLayer._layerConfig.paint['intersect-slope-angle-mask'] = ranges
    this._intersectLayer.update()
    this._layerConfig.paint['slope-angle-mask'] = ranges
    super.update()
  }
}
