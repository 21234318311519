import { IntersectableLayer } from './IntersectableLayer.js';

export class ElevationRangeLayer extends IntersectableLayer {
  constructor(onyx, intersectLayer) {
    super(onyx, {
      id: 'elevation$shade',
      type: 'elevation',
      source: 'elevation',
      layout: {
        'visibility': 'none',
        'elevation-gradient': [
          'gradient',
          [
            0,    '#023020',
            1000, '#90EE90',
            2000, '#FFFFE0',
            3000, '#CC5500',
            4000, '#8B0000',
            5000, '#5C4033',
          ],
        ],
      },
      paint: {
        'elevation-opacity': 0.6,
      },
    }, intersectLayer)
  }

  update(start, end) {
    console.assert(typeof start === 'number')
    console.assert(typeof end === 'number')
    const ranges = [['range', start, end]]
    this._intersectLayer._layerConfig.paint['intersect-elevation-mask'] = ranges
    this._intersectLayer.update()
    this._layerConfig.paint['elevation-mask'] = ranges
    super.update()
  }
}
