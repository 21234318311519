import '../../../css/small-tools.css';
import appService from '../../services/app-service.js';
import './flyby-button-component.js';
import './viewshed-button-component.js';
import './waypoint-button-component.js';

class SmallToolsContainerComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      .small-tools-container {
        position: absolute;
        top: 10px;
        left: 395px;
        transition: top 0.2s ease, left 0.2s ease;
      }

      .small-tools-container.collapsed {
        top: 84px;
        left: 10px;
      }

      flyby-button-component.hide {
        display: none;
      }
    </style>

    <div class="small-tools-container">
      <viewshed-button-component></viewshed-button-component>  
      <waypoint-button-component></waypoint-button-component> 
      <flyby-button-component class="hide"></flyby-button-component>
    </div>
    `

    if (appService.isOffroad()) {
      document.querySelector('flyby-button-component').classList.remove('hide')
    }
  }
}

customElements.define(
  'small-tools-container-component',
  SmallToolsContainerComponent
)
