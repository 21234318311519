class ZoomControlComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <style>

    #zoom-control {
      position: absolute;
      bottom: 170px;
      right: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .zoom-button, .rotate-button {
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.75);
        border: none;
        color: #fff;
        outline: none;
        transition: background-color 250ms ease;
    }

    .control-btn:hover {
      background-color: rgba(0, 0, 0, 1);
    }

    </style>

    <div id="zoom-control">
      <button class="control-btn rotate-button" id="pitch-down"><i class="fa fa-arrow-up"></i></button>
      <button class="control-btn rotate-button" id="pitch-up"><i class="fa fa-arrow-down"></i></button>
      <button class="control-btn rotate-button" id="rotate-left"><i class="fa fa-rotate-left"></i></button>
      <button class="control-btn rotate-button" id="rotate-right"><i class="fa fa-rotate-right"></i></button>
      <button class="control-btn zoom-button" id="zoom-plus"><i class="fa fa-plus"></i></button>
      <button class="control-btn zoom-button" id="zoom-minus"><i class="fa fa-minus"></i></button>
    </div> 
    `

    // add listeners for control buttons
    document.getElementById('zoom-plus').addEventListener('click', this.zoomIn)
    document
      .getElementById('zoom-minus')
      .addEventListener('click', this.zoomOut)

    document
      .getElementById('rotate-right')
      .addEventListener('click', this.rotateRight)

    document
      .getElementById('rotate-left')
      .addEventListener('click', this.rotateLeft)

    document.getElementById('pitch-up').addEventListener('click', this.pitchUp)
    document
      .getElementById('pitch-down')
      .addEventListener('click', this.pitchDown)

    // add listeners for holding down +/- buttons
    let zoomInCounter
    let zoomOutCounter
    let rotateRightCounter
    let rotateLeftCounter
    let pitchUpCounter
    let pitchDownCounter

    /**
     * PLUS
     */
    document.getElementById('zoom-plus').addEventListener('mousedown', () => {
      zoomInCounter = setInterval(() => {
        this.zoomIn()
      }, 100)
    })
    document.getElementById('zoom-plus').addEventListener('mouseup', () => {
      clearInterval(zoomInCounter)
    })
    document.getElementById('zoom-plus').addEventListener('mouseleave', () => {
      clearInterval(zoomInCounter)
    })

    /**
     * MINUS
     */
    document.getElementById('zoom-minus').addEventListener('mousedown', () => {
      zoomOutCounter = setInterval(() => {
        this.zoomOut()
      }, 100)
    })

    document.getElementById('zoom-minus').addEventListener('mouseup', () => {
      clearInterval(zoomOutCounter)
    })

    document.getElementById('zoom-minus').addEventListener('mouseleave', () => {
      clearInterval(zoomOutCounter)
    })

    /**
     * RIGHT
     */
    document
      .getElementById('rotate-right')
      .addEventListener('mousedown', () => {
        rotateRightCounter = setInterval(() => {
          this.rotateRight()
        }, 100)
      })

    document.getElementById('rotate-right').addEventListener('mouseup', () => {
      clearInterval(rotateRightCounter)
    })

    document
      .getElementById('rotate-right')
      .addEventListener('mouseleave', () => {
        clearInterval(rotateRightCounter)
      })

    /**
     * LEFT
     */
    document.getElementById('rotate-left').addEventListener('mousedown', () => {
      rotateLeftCounter = setInterval(() => {
        this.rotateLeft()
      }, 100)
    })

    document.getElementById('rotate-left').addEventListener('mouseup', () => {
      clearInterval(rotateLeftCounter)
    })

    document
      .getElementById('rotate-left')
      .addEventListener('mouseleave', () => {
        clearInterval(rotateLeftCounter)
      })

    /**
     * UP
     */
    document.getElementById('pitch-up').addEventListener('mousedown', () => {
      pitchUpCounter = setInterval(() => {
        this.pitchUp()
      }, 100)
    })

    document.getElementById('pitch-up').addEventListener('mouseup', () => {
      clearInterval(pitchUpCounter)
    })

    document.getElementById('pitch-up').addEventListener('mouseleave', () => {
      clearInterval(pitchUpCounter)
    })

    /**
     * DOWN
     */
    document.getElementById('pitch-down').addEventListener('mousedown', () => {
      pitchDownCounter = setInterval(() => {
        this.pitchDown()
      }, 100)
    })

    document.getElementById('pitch-down').addEventListener('mouseup', () => {
      clearInterval(pitchDownCounter)
    })

    document.getElementById('pitch-down').addEventListener('mouseleave', () => {
      clearInterval(pitchDownCounter)
    })

    // add listeners for + and - keys
    document.addEventListener('keydown', (event) => {
      if (event?.code === 'NumpadAdd' || event?.code === 'Equal') {
        // + key on number pad or +/= key on regular keyboard
        this.zoomIn()
      } else if (event?.code === 'NumpadSubtract' || event?.code === 'Minus') {
        // - key on number pad or -/_ key on regular keyboard
        this.zoomOut()
      }
    })
  }

  zoomIn() {
    document.dispatchEvent(new CustomEvent('zoom-in'))
  }

  zoomOut() {
    document.dispatchEvent(new CustomEvent('zoom-out'))
  }

  rotateRight() {
    document.dispatchEvent(new CustomEvent('rotate-right'))
  }

  rotateLeft() {
    document.dispatchEvent(new CustomEvent('rotate-left'))
  }

  pitchUp() {
    document.dispatchEvent(new CustomEvent('pitch-up'))
  }

  pitchDown() {
    document.dispatchEvent(new CustomEvent('pitch-down'))
  }

  zoomOn(event) {
    document.dispatchEvent(
      new CustomEvent('zoom-on', {
        detail: { x: event.offsetX, y: event.offsetY },
      })
    )
  }
}

customElements.define('zoom-control-component', ZoomControlComponent)
