import { retrieveToken } from '../utilities/token.js'

const environment = 'production'
const profileUrl = `https://api.${environment}.onxmaps.com/v1/profile`

class ProfileService {
  constructor() {
    this.profile = null
  }

  /**
   * Fetch the current user profile and save it
   */
  async fetchCurrentUserProfile() {
    try {
      const response = await fetch(profileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${retrieveToken()}`,
        },
      })

      const data = await response.json()

      this.profile = data
    } catch (error) {
      // fail silently
    }
  }

  /**
   * Get the current user profile
   * @returns {Object} current user profile
   */
  async getCurrentUserProfile() {
    if (!this.profile) {
      await this.fetchCurrentUserProfile()
    }

    return this.profile
  }

  /**
   * Get the current user email
   * @returns {String} current user email
   */
  async getCurrentUserEmail() {
    const profile = await this.getCurrentUserProfile()

    return profile?.email || ''
  }

  /**
   * Clear the current user profile
   */
  clearCurrentUserProfile() {
    this.profile = null
  }
}

const profileService = new ProfileService()
export default profileService
