import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import markupService from './markup-service.js';

const trackedFps = []
const trackedLongFrameTime = []
const trackedAvgFrameTime = []

let app, analytics, performance, map

/**
 * Init firebase performance and analytics
 * @param {Object} mapInstance - core viewer instance
 */
export function initPerformance(mapInstance) {
  map = mapInstance

  const firebaseConfig = {
    apiKey: 'AIzaSyAC1_6LBfOENnKnDdYez5KBiVXsZt-6wdY',
    authDomain: 'onx-labs.firebaseapp.com',
    projectId: 'onx-labs',
    storageBucket: 'onx-labs.appspot.com',
    messagingSenderId: '46291414833',
    appId: '1:46291414833:web:f19afac6937676423b8a10',
    measurementId: 'G-9DE2RZSXF6',
  }

  // Initialize Firebase
  app = initializeApp(firebaseConfig)
  analytics = getAnalytics(app)
  performance = getPerformance(app)

  // setup tracking of fps, avg frame time, long frame time, etc.
  _setupTracking()
}

/**
 * Log an event to firebase analytics
 * @param {String} name - event name
 * @param {Object} props - event props
 */
export function logPerformanceMetric(name, props) {
  // add markups info to every event
  props.num_points = Object.keys(markupService.getWaypoints()).length
  props.num_lines =
    Object.keys(markupService.getLines()).length +
    Object.keys(markupService.getTracks()).length
  props.num_polygons = Object.keys(markupService.getAreas()).length

  logEvent(analytics, name, props)
  console.log(
    `performance metric logged: ${name}, props: ${JSON.stringify(props)}`
  )
}

/**
 * Set up tracking of metrics through session
 */
function _setupTracking() {
  // record fps and avg frame time every second
  setInterval(() => {
    const frameTime = map.getAvgFrameTime()
    const frameRate = Math.round((1 / frameTime) * 1000 * 100) / 100
    trackedFps.push(frameRate)
    trackedAvgFrameTime.push(frameTime)
  }, 1000)

  // record long frame on event
  map.addEventCallback(map.EventType.LONG_FRAME, () => {
    // add to tracked long frames
    const frameTime = map.getLastFrameTime()
    trackedLongFrameTime.push(frameTime)

    // send specific long frame info for this instance
    const cameraState = map.getCameraState(map.MAIN_VIEWPORT_ID)
    // TODO: Update this when API includes cull result.
    //const cullResult = map.getCullResult()
    let params = cameraState
    params.position = JSON.stringify(params.position)
    params.time = Math.round(frameTime * 100) / 100
    //params.bounds = JSON.stringify(cullResult.bounds)
    //params.minLevel = cullResult.minLevel
    //params.maxLevel = cullResult.maxLevel
    //params.avgLevel = cullResult.avgLevel
    //params.selected = cullResult.selected
    logPerformanceMetric('long_frame_info', params)
  })

  // send camera state, recent tiles, cull result, etc. every 2 minutes
  setInterval(() => {
    const cameraState = map.getCameraState(map.MAIN_VIEWPORT_ID)
    const recentTiles = map.getRecentTiles(120000)
    // TODO: Update this when API includes cull result.
    //const cullResult = map.getCullResult()

    cameraState.position = JSON.stringify(cameraState.position)
    logPerformanceMetric('camera_state', cameraState)
    logPerformanceMetric('recent_tiles', {
      num_tiles: recentTiles.length,
    })
    //cullResult.tileIds = JSON.stringify(cullResult.tileIds)
    //cullResult.bounds = JSON.stringify(cullResult.bounds)
    //logPerformanceMetric('cull_result', cullResult)
  }, 120000)
}

/**
 * Send metrics for the session
 */
export function sendSessionMetrics() {
  logPerformanceMetric('fps', _getFpsForSession())
  logPerformanceMetric('avg_frame_time', _getAvgFrameTimeForSession())
  logPerformanceMetric('long_frame_time', _getLongFrameTimeForSession())
}

/**
 * Get min, max, and avg of fps for session
 */
function _getFpsForSession() {
  const max = Math.round(Math.max(...trackedFps) * 100) / 100
  const min = Math.round(Math.min(...trackedFps) * 100) / 100
  const avg =
    Math.round((trackedFps.reduce((a, b) => a + b) / trackedFps.length) * 100) /
    100

  return { min, max, avg }
}

/**
 * Get min, max, and avg of avg frame time for session
 */
function _getAvgFrameTimeForSession() {
  const max = Math.round(Math.max(...trackedAvgFrameTime) * 100) / 100
  const min = Math.round(Math.min(...trackedAvgFrameTime) * 100) / 100
  const avg =
    Math.round(
      (trackedAvgFrameTime.reduce((a, b) => a + b) /
        trackedAvgFrameTime.length) *
        100
    ) / 100

  return { min, max, avg }
}

/**
 * Get min, max, and avg of long frame time for session
 */
function _getLongFrameTimeForSession() {
  const max = Math.round(Math.max(...trackedLongFrameTime) * 100) / 100
  const min = Math.round(Math.min(...trackedLongFrameTime) * 100) / 100
  const avg =
    Math.round(
      (trackedLongFrameTime.reduce((a, b) => a + b) /
        trackedLongFrameTime.length) *
        100
    ) / 100

  return { min, max, avg }
}
