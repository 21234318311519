import './slider-switch-component.js'
import './slope-aspect-picker-component.js'
import './elevation-picker-component.js'
import * as bootstrap from 'bootstrap'
import '../../../css/terrain-tools.css'
import { getViewshedState } from './viewshed-button-component.js'
import * as marketing from '../../utilities/marketing.js'
import { getSlopeAspectRange } from './slope-aspect-picker-component.js'
import { getSlopeAngleRange } from './slope-angle-picker-component.js'
import { getElevationRange } from './elevation-picker-component.js'

class ElevationBandComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      .elevation-band-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    </style>

    <div class='terrain-tool-container'>
      <h5 class="elevation-band-header">
        <a href="#" style="text-decoration:none;color:white;" id="elevation-band-tooltip-trigger" data-bs-toggle="tooltip" data-bs-placement="right" title="This filter allows you to identify areas within a certain elevation range.">
          Elevation Band <i class="fa fa-question-circle" aria-hidden="true"></i>
        </a>
        <slider-switch-component id="elevation-band-toggle" collapse-element-id="elevation-band-body"></slider-switch-component>
      </h5>
      <div id='elevation-band-body' class="collapse">
        <elevation-picker-component></elevation-picker-component>
      </div>
    </div> 
    `

    const tooltipTriggerEl = document.getElementById(
      'elevation-band-tooltip-trigger'
    )
    new bootstrap.Tooltip(tooltipTriggerEl)
    tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
      marketing.logEvent('terrain_x_help_expanded', {
        origin: 'elevation band help icon',
      })
    })

    const slider = document.getElementById('elevation-band-toggle')
    slider.addEventListener('on-slider-change', (event) => {
      const myEvent = new CustomEvent('on-elevation-band-toggled', {
        detail: event.detail,
      })
      document.dispatchEvent(myEvent)

      const elevRange = getElevationRange()
      const slopeAngleRange = getSlopeAngleRange()
      const slopeAspectRange = getSlopeAspectRange()
      marketing.logEvent('terrain_x_filters_modified', {
        modified_filter: 'elevation band',
        filter_status: event.detail ? 'on' : 'off',
        origin: 'elevation band toggle',
        viewshed_state: getViewshedState(),
        slope_angle: `${slopeAngleRange.min}-${slopeAngleRange.max}`,
        slope_aspect: slopeAspectRange.all
          ? 'all'
          : `${slopeAspectRange.start}-${slopeAspectRange.end}`,
        elevation: `${elevRange.min}-${elevRange.max}`,
      })
    })
  }
}

customElements.define('elevation-band-component', ElevationBandComponent)
