class SupergraphService {
  constructor() {
    this.trails = {}
  }

  /**
   * Add a trail (used from api response)
   * @param {Object} trail - trail object
   */
  addTrail(trail) {
    this.trails[trail.id] = trail
  }

  /**
   * Gets all featured trails
   * @returns {Object} featured trails object
   */
  getTrails() {
    return this.trails
  }

}

const supergraphService = new SupergraphService()
export default supergraphService
