import mParticle from '@mparticle/web-sdk'
import amplitudeKit from '@mparticle/web-amplitude-kit'

import profileService from '../services/profile-service.js'

let marketingEnabled = false

/**
 * Initialize mParticle and the Amplitude-mParticle integration kit
 */
export async function initialize() {
  /**
   * If prebid-ads.js is loaded, then it sets a global window variable that marketing is enabled.
   *
   * This idea is taken from https://stackoverflow.com/a/20505898/8894424
   * Most ad blockers will not load urls with "ad" or "prebid" in them. So if this file is blocked
   * we need to send marketing events through the analytics router
   */
  marketingEnabled = window.marketingEnabled

  // initialize mparticle and amplitude sdk
  const userProfile = await profileService.getCurrentUserProfile()
  const mParticleConfig = {
    isDevelopmentMode: window.location.host !== 'labs.onxmaps.com',
    identifyRequest: {
      userIdentities: {
        email: userProfile?.email,
        customerid: userProfile?.account_id,
      },
    },
  }

  amplitudeKit.register(mParticleConfig)

  mParticle.init('us1-2728dc7b99155d4a883b84afb5a0cc84', mParticleConfig)
}

/**
 * Resolves when mParticle is ready to log events
 * @returns {Promise}
 */
export async function mParticleReady() {
  return new Promise((resolve) => {
    mParticle.ready(() => {
      resolve()
    })
  })
}

/**
 * Log event
 * @param {String} name - event name
 * @param {Object} props - event props
 */
export async function logEvent(name, props) {
  if (marketingEnabled) {
    // log event directly to mparticle
    await mParticleReady()

    const type = mParticle.EventType.Other
    mParticle.logEvent(name, type, props)
  } else {
    // use analytics router to get around ad blocking
    const body = {
      event_name: name,
      event_params: props,
    }

    await fetch('https://api.production.onxmaps.com/v1/analytics/mparticle', {
      method: 'POST',
      headers: {
        'ONX-Application-ID': 'hunt',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${retrieveToken()}`,
      },
      body: JSON.stringify(body),
    })
  }

  // log event to console for debugging
  console.log(
    `Marketing event logged - name: ${name}, props: ${JSON.stringify(props)}`
  )
}
