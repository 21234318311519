import * as bootstrap from 'bootstrap'
import waypointImage from '../../../assets/waypoint.svg'
import waypointTooltipImage from '../../../assets/waypoint-tooltip.png'
import { getViewshedState } from './viewshed-button-component.js'
import * as marketing from '../../utilities/marketing.js'
import router from '../app-component.js'
import appService from '../../services/app-service.js'

let inCreateMode = false

class WaypointButtonComponent extends HTMLElement {
  constructor() {
    super()
  }
  getApp() {
    return appService.getApp()
  }
  getAppClass() {
    return appService.appClass()
  }
  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      #waypoint-button .small-tools-icon {
        font-size: 40px;
        display: flex;
        justify-content: center;
      }

      .waypoint-tooltip .content {
        display: flex;
        flex-direction: column;
        background-color: #000;
        padding: 20px;
        border-radius: 16px;
        max-width: 375px;
      }

      .waypoint-tooltip .tooltip-inner {
        max-width: 100%;
        padding: 0;
        text-align: left;
        margin-top: 10px;
      }

      .waypoint-tooltip .tooltip-title {
        font-family: 'Atlas Grotesk', sans-serif;
        font-weight: 900;
        font-size: 18px;
        color: #fff;
        margin-bottom: 10px;
      }

      .waypoint-tooltip img {
        width: 100%;
        height: auto;
      }
    </style>

    <div id='waypoint-button' class='small-tools-button ${this.getAppClass()}'>
    <div class="small-tools-tool-tip">
    <a href="#" style="text-decoration:none;color:white;" id="waypoint-tooltip-trigger" data-bs-toggle="tooltip" data-bs-placement="right" title="Use your mouse cursor and click to drop a new waypoint anywhere on the map. This feature is in beta. You will be able to view, edit, or remove the waypoint back in the onX webmap.">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
    </a>
  </div>
      <div class="small-tools-icon"><i class="fa fa-map-marker text-light"></i></div>
      <div class="small-tools-title">Waypoint</div>
    </div> 
    `

    const tooltipTriggerEl = document.getElementById('waypoint-tooltip-trigger')
    new bootstrap.Tooltip(tooltipTriggerEl, {
      customClass: 'waypoint-tooltip',
      container: 'body',
      template: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="content"><div class="tooltip-title">Add Waypoint</div><img src="${waypointTooltipImage}"/><div class="tooltip-inner"></div></div></div>`,
    })
    tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
      marketing.logEvent('terrain_x_help_expanded', {
        origin: 'waypoint tool help icon',
      })
    })

    const waypointBtn = document.getElementById('waypoint-button')
    waypointBtn.onclick = () => {
      handleWaypointButtonClick()
    }

    /**
     * Handle clicking on the waypoint button
     */
    const handleWaypointButtonClick = () => {
      if (inCreateMode) {
        exitCreateMode()

        marketing.logEvent('waypoint_tool_disabled', {
          viewshed_state: getViewshedState(),
        })
      } else {
        enterCreateMode()

        marketing.logEvent('waypoint_tool_enabled', {
          viewshed_state: getViewshedState(),
        })
      }
    }

    /**
     * Enter waypoint create mode
     */
    const enterCreateMode = () => {
      // update state
      inCreateMode = true

      // show waypoint button as selected
      const waypointBtn = document.getElementById('waypoint-button')
      waypointBtn.classList.add('selected')

      // show waypoint cursor
      document.body.style.cursor = `url(${waypointImage}) 17 46, default`

      // add listener for map click
      canvas.addEventListener('click', handleMapClick)
    }

    /**
     * Exit waypoint create mode
     */
    const exitCreateMode = () => {
      // update state
      inCreateMode = false

      // show waypoint button as unselected
      const waypointBtn = document.getElementById('waypoint-button')
      waypointBtn.classList.remove('selected')

      // remove waypoint cursor
      document.body.style.cursor = 'default'

      // remove listener for map click
      canvas.removeEventListener('click', handleMapClick)
    }

    /**
     * Handle a click on the map to place a waypoint
     * @param {Event} event - map click event
     * @returns if not in create mode
     */
    const handleMapClick = (event) => {
      if (!inCreateMode) {
        return
      }

      // add waypoint to map
      const myEvent = new CustomEvent('on-waypoint-create', {
        detail: event,
      })
      document.dispatchEvent(myEvent)

      showCreateCard()

      exitCreateMode()
    }

    /**
     * Show the waypoint create card
     */
    const showCreateCard = () => {
      marketing.logEvent('waypoint_create_initiated', {
        viewshed_state: getViewshedState(),
        origin: 'terrain-x',
      })
      router.navigate(`/${this.getApp()}/waypoint-create`)
    }
  }
}

customElements.define('waypoint-button-component', WaypointButtonComponent)
