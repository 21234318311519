import { IntersectableLayer } from './IntersectableLayer.js';

export class SlopeAspectLayer extends IntersectableLayer {
  constructor(onyx, intersectLayer) {
    super(
      onyx,
      {
        id: 'slope$aspect$shade',
        type: 'slope-aspect',
        source: 'elevation',
        layout: {
          'visibility': 'none',
          'slope-aspect-gradient': [
            'gradient',
            [
              22.5,  '#394DE5', 22.5,  '#39A9E5',
              67.5,  '#39A9E5', 67.5,  '#39E5C6',
              112.5, '#39E5C6', 112.5, '#FFFF40',
              157.5, '#FFFF40', 157.5, '#FFCC33',
              202.5, '#FFCC33', 202.5, '#FF9226',
              247.5, '#FF9226', 247.5, '#FF5319',
              292.5, '#FF5319', 292.5, '#8139E5',
              337.5, '#8139E5', 337.5, '#394DE5',
            ],
            360,
          ],
        },
        paint: {
          'slope-aspect-opacity': 0.4,
          'slope-aspect-minimum-angle': 5,
        },
      },
      intersectLayer
    )
  }

  update(ranges) {
    console.assert(Array.isArray(ranges))
    const masks = ranges.map(range => ['range', range[0], range[1]])
    this._intersectLayer._layerConfig.paint['intersect-slope-aspect-mask'] = masks
    this._intersectLayer._layerConfig.paint['intersect-slope-aspect-minimum-angle'] = (this.isEntire(ranges)) ? 0 : 5
    this._intersectLayer.update()
    this._layerConfig.paint['slope-aspect-mask'] = masks
    super.update()
  }

  // NOTE this is not a true mathematical function, it just assumes that the input will be structured properly for these checks
  isEntire(ranges) {
    if (ranges.length === 8) {
      return true
    } else if (ranges.length === 1) {
      const begin = ranges[0][0]
      const end = ranges[0][1]
      return begin === 0 && end === 360
    } else {
      return false
    }
  }
}
