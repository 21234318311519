import * as bootstrap from 'bootstrap'
import viewshedTooltipImage from '../../../assets/viewshed-tooltip.png'
import * as marketing from '../../utilities/marketing.js'
import appService from '../../services/app-service.js'
let state = 'disabled'

export function getViewshedState() {
  return state
}

export function setViewshedState(value) {
  state = value
}

class ViewshedButtonComponent extends HTMLElement {
  constructor() {
    super()
  }
  getAppClass() {
    return appService.appClass()
  }
  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      .viewshed-tooltip .content {
        display: flex;
        flex-direction: column;
        background-color: #000;
        padding: 20px;
        border-radius: 16px;
        max-width: 375px;
      }

      .viewshed-tooltip .tooltip-inner {
        max-width: 100%;
        padding: 0;
        text-align: left;
        margin-top: 10px;
      }

      .viewshed-tooltip .tooltip-title {
        font-family: 'Atlas Grotesk', sans-serif;
        font-weight: 900;
        font-size: 18px;
        color: #fff;
        margin-bottom: 10px;
      }

      .viewshed-tooltip img {
        width: 100%;
        height: auto;
      }

      #viewshed-tip {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: #bdbdbd;
        background-color: rgba(0, 0, 0, 0.75);
        padding: 10px;
        border-radius: 10px;
        pointer-events: none;
        font-style: italic;
        transition: opacity 0.2s ease;
        opacity: 1;
      }

      #viewshed-tip.hidden {
        opacity: 0;
      }

    </style>

    <div id='viewshed-button' class='small-tools-button ${this.getAppClass()}'>
      <div class="small-tools-tool-tip">
        <a href="#" style="text-decoration:none;color:white;" id="viewshed-tooltip-trigger" data-bs-toggle="tooltip" data-bs-placement="right" title="This tool allows you to visualize what can be seen from any point on the map. Once enabled, the viewshed will follow your cursor. You can fix the viewshed position in place by double right clicking on the map.">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
        </a>
      </div>
      <div class="small-tools-icon"><i class="fa fa-binoculars text-light"></i></div>
      <div class="small-tools-title">Viewshed</div>
    </div> 

    <div id="viewshed-tip" class="hidden">
      Tip: fix the viewshed position in place by double right clicking on the map
    </div>
    `

    const tooltipTriggerEl = document.getElementById('viewshed-tooltip-trigger')
    new bootstrap.Tooltip(tooltipTriggerEl, {
      customClass: 'viewshed-tooltip',
      container: 'body',
      template: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="content"><div class="tooltip-title">Viewshed</div><img src="${viewshedTooltipImage}"/><div class="tooltip-inner"></div></div></div>`,
    })
    tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
      marketing.logEvent('terrain_x_help_expanded', {
        origin: 'viewshed help icon',
      })
    })

    document.getElementById('viewshed-button').onclick = () => {
      // toggle the button
      const btn = document.getElementById('viewshed-button')
      const className = 'selected'
      btn.classList.toggle(className)

      // toggle the tip text
      const tip = document.getElementById('viewshed-tip')
      tip.classList.toggle('hidden')

      const toggledOn = btn.classList.contains(className)
      const myEvent = new CustomEvent('on-viewshed-toggled', {
        detail: toggledOn,
      })
      document.dispatchEvent(myEvent)

      setViewshedState(toggledOn ? 'enabled' : 'disabled')

      marketing.logEvent(`terrain_x_viewshed_${getViewshedState()}`)
    }
  }
}

customElements.define('viewshed-button-component', ViewshedButtonComponent)
