import appService from '../../services/app-service.js';
import markupService from '../../services/markup-service.js';
import * as recording from '../../services/recording-service.js';
import supergraphService from '../../services/supergraph-service.js';

class FlybyCardComponent extends HTMLElement {
  constructor() {
    super()
    this.selectedId = null
  }

  getApp() {
    return appService.getApp()
  }

  getAppClass() {
    return appService.appClass()
  }

  
  /***
   * @param time {int}
   */
  handleButtonFlybyTime(time) {
    const myEvent = new CustomEvent('on-flyby-set-time', {
      detail: { time: time },
    })
    document.dispatchEvent(myEvent)
  }

  /***
   * @param radius {float}
   * @param pitch {float}
   */
  handleButtonFlybyGuides(radius, pitch) {
    const myEvent = new CustomEvent('on-flyby-set-guides', {
      detail: { 
        radius: radius,
        pitch: pitch
      },
    })
    document.dispatchEvent(myEvent)
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
        .disclaimer {
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            margin: 15px 0 30px;
        }

        #flyby-name-input {
            margin-bottom: 15px;
            width: 100%;
            padding: 5px 10px;
            font-size: 24px;
            font-family: 'Atlas Grotesk', sans-serif;
            border: 2px solid #bdbdbd;
            border-radius: 5px;
            color: #8b8b8b;
        }

        #flyby-name-input:focus {
            outline: none;
            box-shadow: 0 0 3px #8b8b8b;
        }

        .action-buttons {
            display: flex;
            justify-content: space-between;
        }

        .path-item__fragment {
          height: 64px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .path-item__contents {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 8px;
        }

        .path-item__fragment:hover {
          background: rgba(255, 255, 255, 0.5);
          color: black;
          cursor: pointer;
        }

        .path-item__labels {
          display: flex;
          flex-direction: row;
          margin-top: auto;
          margin-bottom: auto;
        }

        .path-item__labels i,
        .path-item__labels p {
          margin: auto 4px;
        }

        .path-item__labels i {
          transform: scale(0.5);
        }

        .path-item__buttons {
          display: flex;
          flex-direction: row;
          margin-top: auto;
          margin-bottom: auto;
        }

        .path-item__buttons.hide {
          display: none;
        }

        @keyframes fadeIn {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }

        .path-item__buttons button {
          animation: fadeIn 2s;
          border-radius: 100px;
          height: 32px;
          width: 32px;
          border-width: 0;
          margin-left: 4px;
          margin-right: 4px;
        }

        .path-item__button-record {
          color: red;
        }

        .flyby-parameters {
          width: 80%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        }

        input[type="radio"] {
          display: none;
        }
        
        .timeLabel {
          border-radius: 10px;
          background-color: rgba(0,0,0,0.5);
          border: none;
          color: white;
          text-align: center;
          display: inline-block;
          font-size: 18px;
          font-family: 'Roboto', sans-serif;
          transition-duration: 0.2s;
          cursor: pointer;
          padding: 10px 30px;
          margin:auto;
        }
        
        .timeLabel:hover {
          background-color: rgba(255,255,255,0.5);
          color: black;
        }
        
        input[type="radio"]:checked + .timeLabel {
          background-color: rgba(255,255,255,0.7);
          color: black;
        }
    </style>

    <template id="path-item__template">
        <div class="path-item__fragment">
          <div class="path-item__contents">
            <div class="path-item__labels">
              <p class="path-item__name"></p>
            </div>
            <div class="path-item__buttons hide">
              <button class="path-item__button-play">
                <i class="fa fa-solid fa-play"></i>
              </button>
              <button class="path-item__button-record">
                <i class="fa fa-solid fa-circle"></i>
              </button>
            </div>
          </div>
        </div>
    </template>

    <div class="no-x-card" id="flyby-card">
      <div class="brand-sub-title" style="color:white">Time</div>
        <div class="flyby-parameters">
          <input type="radio" id="button30" name="timeButtons" checked>
          <label class="timeLabel" for="button30">30 sec</label>
          <input type="radio" id="button60" name="timeButtons">
          <label class="timeLabel" for="button60">60 sec</label>
        </div>
      <div class="brand-sub-title" style="color:white">Camera Pitch</div>
        <div class="flyby-parameters">
          <input type="radio" id="buttonHigh" name="pitchButtons" checked>
          <label class="timeLabel" for="buttonHigh">High</label>
          <input type="radio" id="buttonLow" name="pitchButtons">
          <label class="timeLabel" for="buttonLow">Close</label>
        </div>
      <div class="brand-sub-title" style="color:white">Trails</div>
        <div id="flyby-card__trails">
      </div>
      <div class="brand-sub-title" style="color:white">Routes</div>
        <div id="flyby-card__routes">
      </div>
      <div class="brand-sub-title" style="color:white">Tracks</div>
        <div id="flyby-card__tracks">
      </div>
    </div>
    `

    const button30 = document.getElementById('button30')
    const button60 = document.getElementById('button60')
    button30.onclick = (_event) => {
      this.handleButtonFlybyTime(30000)
    }

    button60.onclick = (_event) => {
      this.handleButtonFlybyTime(60000)
    }

    const buttonLow = document.getElementById('buttonLow')
    const buttonHigh = document.getElementById('buttonHigh')
    buttonHigh.onclick = (_event) => {
      this.handleButtonFlybyGuides(1000, 60)
    }

    buttonLow.onclick = (_event) => {
      this.handleButtonFlybyGuides(500, 70)
    }

    this.populateLists()

    document.dispatchEvent(new CustomEvent('on-open-flyby', {}))

    document.addEventListener('trails-loaded', () => this.populateLists())
    document.addEventListener('routes-loaded', () => this.populateLists())
    document.addEventListener('tracks-loaded', () => this.populateLists())
  }

  populateLists() {
    // Insert trails
    {
      const trailItemTemplate = document.getElementById('path-item__template')
      const trails = supergraphService.getTrails()
      const trailsRoot = document.getElementById('flyby-card__trails')
      while (trailsRoot.firstChild) trailsRoot.removeChild(trailsRoot.firstChild)
      for (const trailKey of Object.keys(trails)) {
        const trail = trails[trailKey]
        const trailItemFrag = trailItemTemplate.content.cloneNode(true)
        this.realizeTemplate(trailsRoot, trail.id, trail.name, trailItemFrag, this.handleTrailFocus, this.handleTrailUnfocus, this.handleTrailFlyby)
      }
    }

    // Insert routes
    {  
      const routeItemTemplate = document.getElementById('path-item__template')
      const routes = markupService.getRoutes()
      const routesRoot = document.getElementById('flyby-card__routes')
      while (routesRoot.firstChild) routesRoot.removeChild(routesRoot.firstChild)
      for (const routeKey of Object.keys(routes)) {
        const route = routes[routeKey]
        const routeItemFrag = routeItemTemplate.content.cloneNode(true)
        this.realizeTemplate(routesRoot, route.id, route.name, routeItemFrag, this.handleRouteFocus, this.handleRouteUnfocus, this.handleRouteFlyby)
      }
    }

    // Insert tracks
    {
      const trackItemTemplate = document.getElementById('path-item__template')
      const tracks = markupService.getTracks()
      const tracksRoot = document.getElementById('flyby-card__tracks')
      while (tracksRoot.firstChild) tracksRoot.removeChild(tracksRoot.firstChild)
      for (const trackKey of Object.keys(tracks)) {
        const track = tracks[trackKey]
        const trackItemFrag = trackItemTemplate.content.cloneNode(true)
        this.realizeTemplate(tracksRoot, track.uuid, track.name, trackItemFrag, this.handleTrackFocus, this.handleTrackUnfocus, this.handleTrackFlyby)
      }
    }
  }

  /**
   * @param root {HTMLElement}
   * @param id {string}
   * @param name {string}
   * @param fragment {DocumentFragment}
   * @param focusCallback {(string) => void}
   * @param unfocusCallback {(string) => void}
   * @param flybyCallback {(string) => void}
   */
  realizeTemplate(root, id, name, fragment, focusCallback, unfocusCallback, flybyCallback) {
    // The top-level element is a document fragment and that doesn't keep any of
    // the events you register to it. If anything needs to be done at a
    // top-level, use contents instead.
    const contents = fragment.querySelector('.path-item__contents')
    contents.id = `path-item__${id}`

    // Name
    const nameEl = fragment.querySelector('.path-item__name')
    nameEl.innerText = name
    
    contents.onclick = (_event) => {
      if (this.selectedId == id) {
        this.unfocusUI(id)
        unfocusCallback(id)
        this.selectedId = null
      } else {
        if (this.selectedId) {
          this.unfocusUI(this.selectedId)
        }
        this.focusUI(id)
        this.selectedId = id
        focusCallback(id)
      }
    }

    // Play button
    const playButtonEl = fragment.querySelector('.path-item__button-play')
    playButtonEl.id = `path-item__button-play__${id}`
    playButtonEl.onclick = (event) => {
      event.stopPropagation()
      flybyCallback(id) }

    // Recording button
    const recordingButtonEl = fragment.querySelector('.path-item__button-record')
    recordingButtonEl.id = `path-item__button-record__${id}`
    recordingButtonEl.onclick = (event) => {
      event.stopPropagation()
      recording.start({ trailData: name })
      flybyCallback(id)
    }

    root.appendChild(fragment)
  }

  /**
   * @param id {string}
   */
  handleTrailFocus(id) {
    const myEvent = new CustomEvent('on-trail-focus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleTrailUnfocus(id) {
    const myEvent = new CustomEvent('on-trail-unfocus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleRouteFocus(id) {
    const myEvent = new CustomEvent('on-route-focus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleRouteUnfocus(id) {
    const myEvent = new CustomEvent('on-route-unfocus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleTrackFocus(id) {
    const myEvent = new CustomEvent('on-track-focus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleTrackUnfocus(id) {
    const myEvent = new CustomEvent('on-track-unfocus', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleTrailFlyby(id) {
    const myEvent = new CustomEvent('on-trail-flyby', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleRouteFlyby(id) {
    const myEvent = new CustomEvent('on-route-flyby', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  handleTrackFlyby(id) {
    const myEvent = new CustomEvent('on-track-flyby', {
      detail: { id: id },
    })
    document.dispatchEvent(myEvent)
  }

  /**
   * @param id {string}
   */
  focusUI(id) {
    const pathEl = document.getElementById(`path-item__${id}`)
    const buttonsContainer = pathEl.querySelector('.path-item__buttons')
    buttonsContainer.classList.remove('hide')
  }

  /**
   * @param id {string}
   */
  unfocusUI(id) {
    const pathEl = document.getElementById(`path-item__${id}`)
    const buttonsContainer = pathEl.querySelector('.path-item__buttons')
    buttonsContainer.classList.add('hide')
  }
}

customElements.define(
  'flyby-card-component',
  FlybyCardComponent
)
