import { v4 as uuid } from 'uuid'
import { retrieveToken } from '../utilities/token.js'
import appService from './app-service.js'

class MarkupService {
  constructor() {
    this.waypoints = {}
    this.lines = {}
    this.routes = {}
    this.areas = {}
    this.tracks = {}
    this.focusedMarkup = null
  }

  getApp() {
    return appService.getApp()
  }

  /**
   * Add an already created waypoint object to the service (used primarily from api response)
   * @param {Object} waypoint - waypoint object already created
   */
  addWaypoint(waypoint) {
    this.waypoints[waypoint.uuid] = waypoint
  }

  /**
   * Remove waypoint from service
   * @param {String} id - waypoint id
   */
  removeWaypoint(id) {
    delete this.waypoints[id]
  }

  /**
   * Add an already created route object to the service (used primarily from api response)
   * @param {Object} route - route object already created
   */
  addRoute(route) {
    this.routes[route.id] = route
  }

  /**
   * Add an already created track object to the service (used primarily from api response)
   * @param {Object} track - track object already created
   */
  addTrack(track) {
    this.tracks[track.uuid] = track
  }

  /**
   * Creates a waypoint object from a given position, adds it to the service, and sets the focused markup
   * @param {Object} position - position object for waypoint location
   * @returns waypoint object
   */
  createWaypoint(position) {
    const waypoint = {
      name: '',
      type: 'waypoint',
      color: [255, 51, 0, 1],
      notes: '',
      uuid: uuid(),
      application: 'onx',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      geo_json: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.lon, position.lat],
        },
        properties: {
          icon: 'Location',
        },
      },
      attachments: {
        photos: { add: [], remove: [] },
      },
    }

    this.addWaypoint(waypoint)
    this.setFocusedMarkup(waypoint)

    return waypoint
  }

  /**
   * Updates waypoint in service
   * @param {String} id - waypoint id
   * @param {Object} updatedWaypoint - updated waypoint object
   */
  updateWaypoint(id, updatedWaypoint) {
    this.waypoints[id] = updatedWaypoint
  }

  /**
   * Gets a waypoint from the service
   * @param {String} id
   * @returns waypoint object
   */
  getWaypoint(id) {
    return this.waypoints[id]
  }

  /**
   * Sets the focused markup
   * @param {Object} markup - markup object
   */
  setFocusedMarkup(markup) {
    this.focusedMarkup = markup
  }

  /**
   * Clears the focused markup
   */
  clearFocusedMarkup() {
    this.focusedMarkup = null
  }

  /**
   * Gets the focused markup
   * @returns markup object
   */
  getFocusedMarkup() {
    return this.focusedMarkup
  }

  /**
   * Makes a request to the backend to create a new waypoint
   * @param {Object} waypoint - waypoint object
   */
  async requestCreateWaypoint(waypoint) {
    try {
      const response = await fetch(
        'https://api.production.onxmaps.com/v1/markups/waypoints',
        {
          method: 'POST',
          headers: {
            'ONX-Application-ID': this.getApp(),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${retrieveToken()}`,
          },
          body: JSON.stringify(waypoint),
        }
      )

      const data = await response.json()

      this.updateWaypoint(waypoint.uuid, data)
    } catch (error) {
      // FUTURE add error message to user
    }
  }

  /**
   * Gets all waypoints
   * @returns {Object} waypoints object
   */
  getWaypoints() {
    return this.waypoints
  }

  /**
   * Gets all lines
   * @returns {Object} lines object
   */
  getLines() {
    return this.lines
  }

  /**
   * Gets all routes
   * @returns {Object} routes object
   */
  getRoutes() {
    return this.routes
  }

  /**
   * Gets all areas
   * @returns {Object} areas object
   */
  getAreas() {
    return this.areas
  }

  /**
   * Gets all tracks
   * @returns {Object} tracks object
   */
  getTracks() {
    return this.tracks
  }
}

const markupService = new MarkupService()
export default markupService
