import router from '../web-components/app-component.js';
export const APP_HUNT = 'hunt'
export const APP_BACKCOUNTRY = 'backcountry'
export const APP_OFFROAD = 'offroad'

class AppService {
  constructor() {
    this.app = null
    this.onyx = null
    this.canvas = null
    this.setApp()
  }

  setApp() {
    const currentLocation = router.getCurrentLocation()
    if (currentLocation?.url?.includes(APP_HUNT)) {
      this.app = APP_HUNT
    } else if (currentLocation?.url?.includes(APP_BACKCOUNTRY)) {
      this.app = APP_BACKCOUNTRY
    } else if (currentLocation?.url?.includes(APP_OFFROAD)) {
      this.app = APP_OFFROAD
    }
  }

  getApp() {
    return this.app
  }

  isHunt() {
    return this.app === APP_HUNT
  }

  isBackcountry() {
    return this.app === APP_BACKCOUNTRY
  }

  isOffroad() {
    return this.app == APP_OFFROAD
  }

  appClass() {
    if (this.isHunt()) {
      return '-hunt'
    } else if (this.isBackcountry()) {
      return '-backcountry'
    } else if (this.isOffroad()) {
      return '-offroad'
    } else {
      return ''
    }
  }
}

const appService = new AppService()
export default appService
