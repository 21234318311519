import stateService from '../../services/state-service.js';
import * as marketing from '../../utilities/marketing.js';
import { getSlopeAngleRange } from './slope-angle-picker-component.js';
import { getSlopeAspectRange } from './slope-aspect-picker-component.js';
import { getViewshedState } from './viewshed-button-component.js';

const ELEVATION_MIN = -2000
const ELEVATION_MAX = 14000
const ELEVATION_RANGE = ELEVATION_MAX - ELEVATION_MIN

let minRange = ELEVATION_MIN
let maxRange = ELEVATION_MAX

export function getElevationRange() {
  return { min: minRange, max: maxRange }
}

class ElevationPickerComponent extends HTMLElement {
  canvas = null

  width = 210
  height = 198

  triHeight = this.height * 0.9
  grabberRadius = 8

  fillColor = '#82CE8A'
  darkHuntOrange = '#761800'

  sliderLineStroke = '#9d1f00'

  constructor() {
    super()

    // Attach a shadow root
    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.innerHTML = String.raw`
      <style>

      .float-right {
        position: absolute;
        right: 18px;
        top: 55px;
        font-size: 0.6em;
      }

      .range-slider {
        position: absolute;
        text-align: center;
        width: ${this.height}px;
        height: ${this.grabberRadius * 2}px;
        /*top: ${this.width * 0.5 - 8}px;*/
        left: ${-this.height * 0.5 + this.grabberRadius}px;
        transform: rotate(-90deg);
      }

      .range-slider input {
        pointer-events: none;
        position: absolute;
        overflow: hidden;
        width: ${this.height}px;
        outline: none;
        left: 0;
        margin: 0;
        padding: 0;
        -webkit-appearance: none;
        background: none;
      }

      .range-slider input::-webkit-slider-thumb {    
        -webkit-appearance: none;
        appearance: none;
        pointer-events: all;
        position: relative;
        z-index: 1000;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 50%;
        width: ${this.grabberRadius * 2}px;
        height: ${this.grabberRadius * 2}px;
      }

      .range-slider input::-moz-range-thumb {
        -moz-appearance: none;
        appearance: none;
        pointer-events: all;
        position: relative;
        z-index: 1000;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 50%;
        width: ${this.grabberRadius * 2}px;
        height: ${this.grabberRadius * 2}px;
        border: none;
      }

      .full-range {
        width: ${this.height - 2}px;
        height: ${this.grabberRadius - 2}px;
        border-radius: ${this.grabberRadius - 2}px;
        left: 0px;
        top: ${this.grabberRadius * 0.5}px;
        position: absolute;
        background: #333;
        z-index: 0;
      }

      .incl-range {
        width: ${this.height - 2}px;
        height: ${this.grabberRadius - 2}px;
        border-radius: ${this.grabberRadius - 2}px;
        left: 0px;
        top: ${this.grabberRadius * 0.5}px;
        position: absolute;
        background: ${this.fillColor};
        z-index: 0;
      }

      canvas {
        position: absolute;
        left:0px;
        top:0px;
      }

      #container {
        display: flex;
        position: relative;
        height: 200px;
        margin-top: 30px;
      }

      #scale-container {
        display: grid;
        grid-template-columns: auto auto;
        width: 51px;
        height: 193px;
      }

      #scale-number-col {
        width: 31px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      #scale-number-col p {
        margin: 0;
        padding: 0;
        text-align: right;
        font-size: 10px;
        color: white;
      }

      #scale-tick-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 20px;
        padding: 6px 0;
      }

      #scale-tick-col hr {
        margin: 0;
        padding: 0;
        border-color: rgb(119, 119, 119);
        opacity: 0.5;
      }

      #scale-tick-col hr.short-tick {
        width: 6px;
      }

      #scale-tick-col hr.long-tick {
        width: 11px;
      }

      </style>

      <div class="float-right" style="color:white;">
        <span id="min-elevation-display">5,000</span> - <span id="max-elevation-display">9,000</span> feet
      </div>

      <div id="container">
        <!-- scale -->
        <div id="scale-container">
          <div id="scale-number-col">
            <p>14,000</p>
            <p>12,000</p>
            <p>10,000</p>
            <p>8,000</p>
            <p>6,000</p>
            <p>4,000</p>
            <p>2,000</p>
            <p>0</p>
            <p>-2,000</p>
          </div>
          <div id="scale-tick-col">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
            <hr class="short-tick">
            <hr class="long-tick">
          </div>
        </div>

        <!-- slider -->
        <div style="position:absolute;left:60px;top:90px;">
          <div style="position:relative;">
            <span class="range-slider">
              <span class="full-range"></span>
              <span class="incl-range"></span>
              <input name="rangeOne" value="${
                this.getState().min
              }" min="${ELEVATION_MIN}" max="${ELEVATION_MAX}" step="100" type="range">
              <input name="rangeTwo" value="${
                this.getState().max
              }" min="${ELEVATION_MIN}" max="${ELEVATION_MAX}" step="100" type="range">
            </span>
          </div>
        </div>

        <!-- mountain -->
        <div style="position:absolute;left:100px;top:10px;">
          <svg width="209" height="179" viewBox="0 0 209 179" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M117.625 56.6591C115.684 59.8706 111.291 59.5794 109.718 56.1349L85.3583 2.76723C83.6741 -0.922408 78.859 -0.922411 77.1749 2.76723L0.487164 170.774C-1.03023 174.098 1.18087 178.007 4.57888 178.007L203.929 178.007C207.573 178.007 209.753 173.574 207.744 170.25L132.167 45.2252C130.359 42.2345 126.345 42.2345 124.537 45.2252L117.625 56.6591Z" fill="#555555"/>
          </svg>
        </div>

        <!-- mountain highlight-->
        <div style="position:absolute;left:100px;top:10px;">
          <svg id="mountain-highlight" width="209" height="179" viewBox="0 0 209 179" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="clip">
              <rect id="clip-rect" x="0" y="10" width="300" height="100" />
            </clipPath>
          </defs>
            <path d="M117.625 56.6591C115.684 59.8706 111.291 59.5794 109.718 56.1349L85.3583 2.76723C83.6741 -0.922408 78.859 -0.922411 77.1749 2.76723L0.487164 170.774C-1.03023 174.098 1.18087 178.007 4.57888 178.007L203.929 178.007C207.573 178.007 209.753 173.574 207.744 170.25L132.167 45.2252C130.359 42.2345 126.345 42.2345 124.537 45.2252L117.625 56.6591Z" 
            fill="#82CE8A" clip-path="url(#clip)"/>
          </svg>
        </div>

      </div>
    `
  }

  getState() {
    return stateService.getState('heightBandFeet')
  }

  updateElevationRange() {
    const inclRange = this.shadowRoot.querySelector('.incl-range')
    const rangeOne = this.shadowRoot.querySelector('input[name="rangeOne"]')
    const rangeTwo = this.shadowRoot.querySelector('input[name="rangeTwo"]')
    const rangeOneFloat = parseFloat(rangeOne.value)
    const rangeTwoFloat = parseFloat(rangeTwo.value)
    minRange = Math.min(rangeOneFloat, rangeTwoFloat)
    maxRange = Math.max(rangeOneFloat, rangeTwoFloat)
    const sliderLengthPercent = ((maxRange - minRange) / ELEVATION_RANGE) * 100
    const sliderOffsetPercent = ((minRange - ELEVATION_MIN) / ELEVATION_RANGE) * 100
    inclRange.style.width = `${sliderLengthPercent}%`
    inclRange.style.left = `${sliderOffsetPercent}%`

    this.shadowRoot.getElementById('min-elevation-display').innerHTML = minRange.toLocaleString('en-US')
    this.shadowRoot.getElementById('max-elevation-display').innerHTML = maxRange.toLocaleString('en-US')
    this.updateMountain()

    document.dispatchEvent(
      new CustomEvent('elevation-changed', {
        detail: { minElevation: minRange, maxElevation: maxRange },
      })
    )
  }

  updateMountain() {
    const rangeOne = this.shadowRoot.querySelector('input[name="rangeOne"]')
    const rangeTwo = this.shadowRoot.querySelector('input[name="rangeTwo"]')
    const max = parseFloat(rangeOne.getAttribute('max'))
    const a =
      Math.min(parseFloat(rangeOne.value), parseFloat(rangeTwo.value)) / max
    const b =
      Math.max(parseFloat(rangeOne.value), parseFloat(rangeTwo.value)) / max

    const H = 179
    const y = (1.0 - b) * H
    const h = (b - a) * H

    const clipRect = this.shadowRoot.getElementById('clip-rect')

    clipRect.setAttribute('y', parseInt(y))
    clipRect.setAttribute('height', parseInt(h))
  }

  sendMarketingEvent() {
    const slopeAngleRange = getSlopeAngleRange()
    const slopeAspectRange = getSlopeAspectRange()
    marketing.logEvent('terrain_x_filters_modified', {
      modified_filter: 'elevation band',
      filter_status: 'on',
      origin: 'elevation band slider',
      viewshed_state: getViewshedState(),
      slope_angle: `${slopeAngleRange.min}-${slopeAngleRange.max}`,
      slope_aspect: slopeAspectRange.all
        ? 'all'
        : `${slopeAspectRange.start}-${slopeAspectRange.end}`,
      elevation: `${minRange}-${maxRange}`,
    })
  }

  connectedCallback() {
    this.updateMountain()

    const rangeOne = this.shadowRoot.querySelector('input[name="rangeOne"]')
    const rangeTwo = this.shadowRoot.querySelector('input[name="rangeTwo"]')
    rangeOne.oninput = () => this.updateElevationRange()
    rangeTwo.oninput = () => this.updateElevationRange()

    this.updateElevationRange()

    rangeOne.onmouseup = () => this.sendMarketingEvent()
    rangeTwo.onmouseup = () => this.sendMarketingEvent()
  }
}

customElements.define('elevation-picker-component', ElevationPickerComponent)
