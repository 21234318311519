import { ANGLE_MAP } from '../web-components/terrain-tools/slope-aspect-rose-picker-component.js';
import appService from './app-service.js';
class StateService {
  constructor() {
    this.defaults = {
      hunt: {
        intersectEnabled: true,
        intersectInverted: true,
        slopeAngleEnabled: true,
        slopeAspectEnabled: true,
        heightBandEnabled: true,
        slopeAngleDegrees: { min: 0, max: 90 },
        slopeAspectDegrees: [
          ANGLE_MAP['N'],
          ANGLE_MAP['NE'],
          ANGLE_MAP['E'],
          ANGLE_MAP['SE'],
          ANGLE_MAP['S'],
          ANGLE_MAP['SW'],
          ANGLE_MAP['W'],
          ANGLE_MAP['NW'],
        ],
        heightBandFeet: { min: -2000, max: 14000 },
        basemapIdx: 0,
      },
      backcountry: {
        intersectEnabled: true,
        intersectInverted: true,
        slopeAngleEnabled: true,
        slopeAspectEnabled: true,
        heightBandEnabled: true,
        slopeAngleDegrees: { min: 0, max: 90 },
        slopeAspectDegrees: [
          ANGLE_MAP['N'],
          ANGLE_MAP['NE'],
          ANGLE_MAP['E'],
          ANGLE_MAP['SE'],
          ANGLE_MAP['S'],
          ANGLE_MAP['SW'],
          ANGLE_MAP['W'],
          ANGLE_MAP['NW'],
        ],
        heightBandFeet: { min: -2000, max: 14000 },
        basemapIdx: 0,
      },
      offroad: {
        intersectEnabled: true,
        intersectInverted: true,
        slopeAngleEnabled: true,
        slopeAspectEnabled: true,
        heightBandEnabled: true,
        slopeAngleDegrees: { min: 0, max: 90 },
        slopeAspectDegrees: [
          ANGLE_MAP['N'],
          ANGLE_MAP['NE'],
          ANGLE_MAP['E'],
          ANGLE_MAP['SE'],
          ANGLE_MAP['S'],
          ANGLE_MAP['SW'],
          ANGLE_MAP['W'],
          ANGLE_MAP['NW'],
        ],
        heightBandFeet: { min: -2000, max: 14000 },
        basemapIdx: 0,
      },
    }

    this.state = {
      intersectEnabled: null,
      intersectInverted: null,
      slopeAngleEnabled: null,
      slopeAspectEnabled: null,
      heightBandEnabled: null,
      slopeAngleDegrees: null,
      slopeAspectDegrees: null,
      heightBandFeet: null,
    }
    this.setDefaults(this.getApp())
  }

  getApp() {
    return appService.getApp()
  }

  setDefaults(app) {
    for (const [key, val] of Object.entries(this.defaults[app])) {
      this.state[key] = val
    }
  }

  getState(key) {
    return this.state[key]
  }

  setState(key, val) {
    this.state[key] = val
  }

  multipleTogglesEnabled() {
    const toggles = [
      this.state.slopeAngleEnabled,
      this.state.slopeAspectEnabled,
      this.state.heightBandEnabled,
    ]
    const countEnabled = toggles.filter((i) => i)
    return countEnabled.length > 1
  }
}

const stateService = new StateService()
export default stateService
