class RadioButtonComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const values = this.getAttribute('values').split(',');
    console.assert(values.length > 0, 'values attribute is required');
    this.shadowRoot.innerHTML = `
      <style>
        #radio-options {
          display: flex;
          flex-direction: column;
        }

        label {
          color: white;
        }

        input {
          accent-color: #65B604;
        }
      </style>
      <div id="radio-options">
        ${values.map((value, i) => {
          const id = value.replace(' ', '-');
          return `
            <div class="radio-wrapper">
              <input id="${id}" type="radio" name="radio-options" value="${value}" ${i === 1 ? 'checked' : ''}>
              <label for="${id}">${value}</label>
            </div>
          `}).join('')}
      </div>
    `;
    const inputs = this.shadowRoot.querySelectorAll('input');
    inputs.forEach(input => {
      input.oninput = event => {
        this.onChange(event);
      }
    })
  }

  onChange(event) {
    if (event.target.checked) {
      this.dispatchEvent(new CustomEvent('toggle-changed', { detail: event.target.value }));
    }
  }
}

customElements.define('radio-button-component', RadioButtonComponent);