import * as bootstrap from 'bootstrap';

const distance = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
  function toRadians(value) {
    return (value * Math.PI) / 180
  }

  const R = 6371.071
  const rlat1 = toRadians(x1) // Convert degrees to radians
  const rlat2 = toRadians(x2) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = toRadians(y2 - y1) // Radian difference (longitudes)
  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  )
}

class GoToLocationControlComponent extends HTMLElement {
  #prevLocation

  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
  
      <style>
  
      #go-to-location-control, #orient-control {
        position: absolute;
        bottom: 120px;
        right: 20px;
        border-radius: 10px;
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.75);
        outline: none;
        font-size: 20px;
        border: none;
        transition: background-color 250ms ease;
      }

      #go-to-location-control:hover, #orient-control:hover {
        background-color: rgba(0, 0, 0, 1);
      }

      #orient-control {
        bottom: 70px;
      }

      #go-to-location-control.disabled {
        pointer-events: none;
      }

      .my-location-popover.popover {
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 10px;
      }

      .my-location-popover .popover-body {
        color: #fff;
        padding-right: 26px;
      }

      .my-location-popover .popover-arrow::after {
        border-top-color: rgba(0, 0, 0, 0.75);
      }
      

      .my-location-popover .popover-close {
        color: #fff;
        font-size: 15px;
        position: absolute;
        right: 12px;
        top: 5px;
        cursor: pointer;
      }
  
      </style>
  
      <div>
        <button id="go-to-location-control" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="In order to use this feature, we need access to your location. Please enable location permissions in your internet browser settings.">
          <i class="fa fa-crosshairs" id="location-icon"></i>
        </button>
        <button id="orient-control" data-bs-toggle="popover" data-bs-placement="bottom">
          <i class="fa fa-globe" id="orient-icon"></i>
        </button>
      </div>
      `

    const locationIcon = document.querySelector('#location-icon')
    const locationButton = document.querySelector('#go-to-location-control')
    const failurePopover = new bootstrap.Popover(locationButton, {
      customClass: 'my-location-popover',
      trigger: 'manual',
      template:
        '<div class="popover" role="tooltip"><div class="popover-arrow"></div><span class="popover-close"><i class="fa fa-close"></i></span><div class="popover-body"></div></div>',
    })

    document
      .getElementById('orient-control')
      .addEventListener('click', this.orientControl)

    locationButton.onclick = () => {
      // set icon to loading
      locationIcon.classList.remove('fa-crosshairs')
      locationIcon.classList.add('fa-spinner', 'fa-spin')

      // disable button
      locationButton.classList.add('disabled')

      // get the users location
      navigator.geolocation.getCurrentPosition(
        goToLocation,
        showLocationFailureDialog,
        {
          timeout: 7000,
        }
      )
    }

    /**
     * On successful retrieval of the user's location, dispatch an event to pan the map to the location.
     * If the user's location was previously known, this callback will be evaluated to identify
     * non-significant changes in location and may behave as a noop when the user spams the button
     * @param {Object} res - GeolocationPosition Object
     */
    const goToLocation = (res) => {
      let { latitude: prevLat = null, longitude: prevLon = null } =
        this.#prevLocation?.coords ?? {}
      this.#prevLocation = res
      if (
        distance(
          { x: prevLon, y: prevLat },
          { x: res?.coords?.longitude, y: res?.coords?.latitude }
        ) > 0.0008
      ) {
        document.dispatchEvent(
          new CustomEvent('on-go-to-coordinates', {
            detail: {
              longitude: res?.coords?.longitude,
              latitude: res?.coords?.latitude,
            },
          })
        )
      }
      resetMyLocationButton()
    }

    /**
     * On failure to retrieve the users location, show an error dialog
     * @param {Object} res - GeolocationPositionError object
     */
    const showLocationFailureDialog = () => {
      // show failure popover
      failurePopover.show()

      // listen for closing failure popover
      const popoverCloseButton = document.querySelector('.popover-close')
      popoverCloseButton.onclick = () => {
        failurePopover.hide()
      }

      resetMyLocationButton()
    }

    /**
     * Reset my location button to default state
     */
    const resetMyLocationButton = () => {
      // set icon to crosshairs
      locationIcon.classList.add('fa-crosshairs')
      locationIcon.classList.remove('fa-spinner', 'fa-spin')

      // re-enable button
      locationButton.classList.remove('disabled')
    }
  }

  orientControl() {
    document.dispatchEvent(new CustomEvent('orient-control'))
  }
}

customElements.define(
  'go-to-location-control-component',
  GoToLocationControlComponent
)
