import * as bootstrap from 'bootstrap'
import waypointTooltipImage from '../../../assets/waypoint-tooltip.png'
import * as marketing from '../../utilities/marketing.js'
import router from '../app-component.js'
import appService from '../../services/app-service.js'

class FlybyButtonComponent extends HTMLElement {
  constructor() {
    super()
  }
  getApp() {
    return appService.getApp()
  }
  getAppClass() {
    return appService.appClass()
  }
  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
      #flyby-button .small-tools-icon {
        font-size: 40px;
        display: flex;
        justify-content: center;
      }

      .flyby-tooltip .content {
        display: flex;
        flex-direction: column;
        background-color: #000;
        padding: 20px;
        border-radius: 16px;
        max-width: 375px;
      }

      .flyby-tooltip .tooltip-inner {
        max-width: 100%;
        padding: 0;
        text-align: left;
        margin-top: 10px;
      }

      .flyby-tooltip .tooltip-title {
        font-family: 'Atlas Grotesk', sans-serif;
        font-weight: 900;
        font-size: 18px;
        color: #fff;
        margin-bottom: 10px;
      }

      .flyby-tooltip img {
        width: 100%;
        height: auto;
      }
    </style>

    <div id='flyby-button' class='small-tools-button ${this.getAppClass()}'>
    <div class="small-tools-tool-tip">
    <a href="#" style="text-decoration:none;color:white;" id="flyby-tooltip-trigger" data-bs-toggle="tooltip" data-bs-placement="right" title="Click to flyby any of your routes/tracks.">
        <i class="fa fa-question-circle" aria-hidden="true"></i>
    </a>
    </div>
      <div class="small-tools-icon"><i class="fa fa-plane text-light"></i></div>
      <div class="small-tools-title">Flyby</div>
    </div> 
    `

    // TODO change tooltip image
    const tooltipTriggerEl = document.getElementById('flyby-tooltip-trigger')
    new bootstrap.Tooltip(tooltipTriggerEl, {
      customClass: 'flyby-tooltip',
      container: 'body',
      template: `<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="content"><div class="tooltip-title">Open Flyby</div><img src="${waypointTooltipImage}"/><div class="tooltip-inner"></div></div></div>`,
    })
    tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
      marketing.logEvent('terrain_x_help_expanded', {
        origin: 'flyby tool help icon',
      })
    })

    const flybyBtn = document.getElementById('flyby-button')
    flybyBtn.onclick = () => {
      // toggle the button
      const btn = document.getElementById('flyby-button')
      const className = 'selected'
      btn.classList.toggle(className)

      const toggledOn = btn.classList.contains(className)
      if (toggledOn) {
        router.navigate(`/${this.getApp()}/flyby`)
      } else {
        const myEvent = new CustomEvent('on-close-flyby',)
        document.dispatchEvent(myEvent)
        router.navigate(`/${this.getApp()}`)
      }
    }

  }
}

customElements.define('flyby-button-component', FlybyButtonComponent)
