import '../../../css/terrain-tools.css';
import appService from '../../services/app-service.js';
import * as marketing from './../../utilities/marketing.js';
import './elevation-band-component.js';
import './intersect-component.js';
import './slope-angle-component.js';
import './slope-aspect-component.js';
import './viewshed-component.js';

class TerrainToolsContainerComponent extends HTMLElement {
  constructor() {
    super()
  }

  getAppClass() {
    return appService.appClass()
  }

  connectedCallback() {
    this.innerHTML = String.raw`

    <div class="terrain-tool-sidebar">
      <div class="terrain-tool-sidebar-header">
        <span class="brand-title ${this.getAppClass()}">
            <svg id="logo" width="29" height="29" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class=${this.getAppClass()} fill-rule="evenodd" clip-rule="evenodd" d="M8.40782 0C3.76431 0 0 3.76431 0 8.40782V34.5922C0 39.2357 3.76431 43 8.40782 43H34.5922C39.2357 43 43 39.2357 43 34.5922V8.40782C43 3.76431 39.2357 0 34.5922 0H8.40782Z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M32 27.9129L27.913 32L21.5003 25.5871L15.087 32L11 27.9129L17.4127 21.5L11 15.0865L15.087 11L21.5003 17.4123L27.913 11L32 15.0865L25.5873 21.5L32 27.9129Z" fill="white"></path>
            </svg>
          Terrain&bull;X
        </span>
        <span class="text-light" id="expand" style="padding-right:10px;"><i class="fa fa-compress" id="expand-icon"></i> </span>
        <div class="brand-sub-title">Analyze, Scout, Discover</div>
      </div>
      <div class="tools-container">
        <!--<viewshed-component></viewshed-component>-->
        <intersect-component></intersect-component>
        <slope-angle-component></slope-angle-component>
        <slope-aspect-component></slope-aspect-component>
        <elevation-band-component></elevation-band-component>
      </div>
    </div>
    
    
    `

    document.getElementById('expand').onclick = () => {
      const sidebarElement = document.querySelector('.terrain-tool-sidebar')
      const smallToolsElement = document.querySelector('.small-tools-container')
      const searchBarElement = document.querySelector(
        '.terrain-analysis-search-container'
      )
      const expandIcon = document.querySelector('#expand-icon')

      const isCollapsed = sidebarElement.classList.contains('collapsed')

      if (isCollapsed) {
        sidebarElement.classList.remove('collapsed')
        smallToolsElement.classList.remove('collapsed')
        searchBarElement.classList.remove('collapsed')
        expandIcon.classList.remove('fa-expand')
        expandIcon.classList.add('fa-compress')

        marketing.logEvent('terrain_x_toolbar_expanded')
      } else {
        sidebarElement.classList.add('collapsed')
        smallToolsElement.classList.add('collapsed')
        searchBarElement.classList.add('collapsed')
        expandIcon.classList.remove('fa-compress')
        expandIcon.classList.add('fa-expand')

        marketing.logEvent('terrain_x_toolbar_collapsed')
      }
    }
  }
}

customElements.define(
  'terrain-tools-container-component',
  TerrainToolsContainerComponent
)
