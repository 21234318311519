import appService from '../../services/app-service.js'
class UpgradeModal extends HTMLElement {
  constructor() {
    super()
  }

  getApp() {
    return appService.getApp()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="modal fade" id="modal-upgrade-form" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <!--<h3><i class="fa fa-sign-out fa-3x"></i></h3>-->
              <h3 class="text-center">Welcome to onX Labs</h3>
              <p class="text-center">Upgrade your membership to Elite to experience onx Labs.</p>
            </div>
            <div class="d-grid gap-2" style="padding-bottom: 10px;">
              <a href="${this.getAttribute(`upgrade-url-${this.getApp()}`)}" 
              class="btn btn-lg btn-hunt btn-block"
                id="">See Upgrade Options</a>
            </div>
          </div>
        </div>
      </div>
    </div>`
  }
}

customElements.define('upgrade-modal-component', UpgradeModal)
