import './slider-switch-component.js'
import './slope-aspect-picker-component.js'
import * as bootstrap from 'bootstrap'
import '../../../css/terrain-tools.css'
import { getSlopeAspectRange } from './slope-aspect-picker-component.js'
import { getSlopeAngleRange } from './slope-angle-picker-component.js'
import { getElevationRange } from './elevation-picker-component.js'
import { getViewshedState } from './viewshed-button-component.js'
import * as marketing from '../../utilities/marketing.js'
import SlopeAspectPickerComponent from './slope-aspect-picker-component.js'
import SlopeAspectRosePickerComponent from './slope-aspect-rose-picker-component.js'
import appService, { APP_HUNT } from '../../services/app-service.js'
class SlopeAspectComponent extends HTMLElement {
  constructor() {
    super()
  }

  getApp() {
    return appService.getApp()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    
    <style>
      .slope-aspect-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    </style>

    <div class='terrain-tool-container'>
      <h5 class="slope-aspect-header">
        <a href="#" style="text-decoration:none;color:white;" id="slope-aspect-tooltip-trigger" data-bs-toggle="tooltip" data-bs-placement="right" title="This is the direction a slope is facing. This filter can be useful for visualizing all north facing slopes, for example.">
          Slope Aspect <i class="fa fa-question-circle" aria-hidden="true"></i>
        </a>
        <slider-switch-component id="slope-aspect-toggle" collapse-element-id="slope-aspect-body"></slider-switch-component>
      </h5>
      <div id='slope-aspect-body' class="collapse">
        <slope-aspect-rose-picker-component></slope-aspect-rose-picker-component>
      </div>
    </div> 
    `

    const tooltipTriggerEl = document.getElementById(
      'slope-aspect-tooltip-trigger'
    )
    new bootstrap.Tooltip(tooltipTriggerEl)
    tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
      marketing.logEvent('terrain_x_help_expanded', {
        origin: 'slope aspect help icon',
      })
    })

    const slider = document.getElementById('slope-aspect-toggle')
    slider.addEventListener('on-slider-change', (event) => {
      const myEvent = new CustomEvent('on-slope-aspect-toggled', {
        detail: event.detail,
      })
      document.dispatchEvent(myEvent)

      const elevRange = getElevationRange()
      const slopeAngleRange = getSlopeAngleRange()
      const slopeAspectRange = getSlopeAspectRange()
      marketing.logEvent('terrain_x_filters_modified', {
        modified_filter: 'slope aspect',
        filter_status: event.detail ? 'on' : 'off',
        origin: 'slope aspect toggle',
        viewshed_state: getViewshedState(),
        slope_angle: `${slopeAngleRange.min}-${slopeAngleRange.max}`,
        slope_aspect: slopeAspectRange.all
          ? 'all'
          : `${slopeAspectRange.start}-${slopeAspectRange.end}`,
        elevation: `${elevRange.min}-${elevRange.max}`,
      })
    })
  }
}

customElements.define('slope-aspect-component', SlopeAspectComponent)
