import profileService from '../../services/profile-service.js'

class AccountButtonsComponent extends HTMLElement {
  constructor() {
    super()
    document.addEventListener(
      'on-login-success',
      async (event) => {
        document
          .getElementById('visitor-account-buttons')
          .classList.add('d-none')
        document
          .getElementById('customer-account-buttons')
          .classList.remove('d-none')

        const userEmail = await profileService.getCurrentUserEmail()
        document.getElementById('signed-in-as').innerHTML =
          userEmail ?? 'Log Out'
      },
      true
    )

    document.addEventListener(
      'on-logout',
      () => {
        document
          .getElementById('visitor-account-buttons')
          .classList.remove('d-none')
        document
          .getElementById('customer-account-buttons')
          .classList.add('d-none')
        document.getElementById('signed-in-as').innerHTML = ''
      },
      true
    )
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <style>

    .account-button-wrapper {
      position: absolute;
      top: 10px;
      right: 145px;
      z-index: 100;
    }

    </style>
    <div class="account-button-wrapper">
      <span id="visitor-account-buttons">
        <a href="${this.getAttribute(
          'sign-up-url'
        )}" class="btn btn-primary btn-hunt navbar-btn hidden-print">Create an Account</a>
        <button type="button" class="btn btn-dark navbar-btn hidden-print" data-bs-toggle="modal"
          data-bs-target="#modal-login-form" id="login-button"><i class="fa fa-sign-in fa-fw"></i>
          Login
        </button>
      </span>
    
      <span id="customer-account-buttons" class="d-none">
        <a class=" x-button secondary-dark small" href="#" id="customLoggedInDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span id="signed-in-as">Elite Customer</span>
        </a>
        <ul class="dropdown-menu" aria-labelledby="customLoggedInDropdown">
          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-logout-form">Logout</a></li>
        </ul>
  
      </span>
    </div>
      `
  }
}

customElements.define('account-buttons-component', AccountButtonsComponent)
