import { getViewshedState } from './viewshed-button-component.js'
import { getSlopeAspectRange } from './slope-aspect-picker-component.js'
import { getElevationRange } from './elevation-picker-component.js'
import * as marketing from '../../utilities/marketing.js'
import stateService from '../../services/state-service.js'

let minAngle = 0
let maxAngle = 90

export function getSlopeAngleRange() {
  return { min: minAngle, max: maxAngle }
}

class IntersectPickerComponent extends HTMLElement {
  height = 50

  grabberRadius = 8

  fillColor = '#5680ED'

  darkHuntOrange = '#761800'

  constructor() {
    super()

    // Attach a shadow root
    this.attachShadow({ mode: 'open' })
  }

  getState() {
    return stateService.getState('intersectInverted')
  }

  updateIntersect(inverted) {
    console.assert(inverted === undefined || typeof inverted === 'boolean');
    document.dispatchEvent(
      new CustomEvent('intersect-changed', {
        detail: { inverted: inverted },
      })
    )
  }

  sendMarketingEvent() {
    const elevRange = getElevationRange()
    const slopeAspectRange = getSlopeAspectRange()
    marketing.logEvent('terrain_x_filters_modified', {
      modified_filter: 'slope angle',
      filter_status: 'on',
      origin: 'slope angle slider',
      viewshed_state: getViewshedState(),
      slope_angle: `${minAngle}-${maxAngle}`,
      slope_aspect: slopeAspectRange.all
        ? 'all'
        : `${slopeAspectRange.start}-${slopeAspectRange.end}`,
      elevation: `${elevRange.min}-${elevRange.max}`,
    })
  }

  connectedCallback() {
    const NOT_INVERTED = "Highlight Intersection";
    const INVERTED = "Darken Complement";
    this.shadowRoot.innerHTML = String.raw`
      <h6 class="invert-header" style="margin:0">
        <radio-button-component id="invert-radio-button" values="${NOT_INVERTED},${INVERTED}"></radio-button-component>
      </h6>
    `;
    const radioButtonComponent = this.shadowRoot.getElementById('invert-radio-button');
    radioButtonComponent.addEventListener('toggle-changed', (event) => {
      this.updateIntersect(event.detail === INVERTED)
    });
  }
}

customElements.define('intersect-picker-component', IntersectPickerComponent)
