import { ArrayBufferTarget, Muxer } from 'mp4-muxer';

/*
  These values are hard-coded based on the codec used.
  If the codec changes, make sure to update the other parameters.
  https://en.wikipedia.org/wiki/Advanced_Video_Coding#Levels
*/
const CODEC = 'avc'
const CODEC_FORMAT = 'avc1.42001f'
const FRAME_RATE = 30
const BITRATE = 1e7
const VIDEO_SIZE = { x: 720, y: 1280 }

const DEFAULT_START_OPTIONS = {
  trailData: '',
}

let onyx = null
let canvas = null
let _isRecording = false
let muxer = null
let videoEncoder = null
let startTime = 0
let lastKeyFrame = 0
let framesGenerated = 0
let intervalId = null

const initialCanvas = {
  size: { x: 0, y: 0 },
  style: {
    width: '',
    height: '',
    display: '',
    marginLeft: '',
    marginRight: '',
  },
}

export function init(onyx_, canvas_) {
  console.assert(onyx_, 'Onyx is required.')
  console.assert(canvas_, 'Canvas is required.')

  onyx = onyx_
  canvas = canvas_
}

export function start({
  trailData = DEFAULT_START_OPTIONS.trailData,
} = DEFAULT_START_OPTIONS) {
  console.log('Recording starting...')

  console.assert(onyx, 'Onyx is not initialized. Call init before start.')
  console.assert(canvas, 'Canvas is not initialized. Call init before start.')
  console.assert(
    !isRecording(),
    'Unable to start recording: recording already in progress.'
  )

  setCanvasToSize(VIDEO_SIZE)

  addOverlay(trailData, VIDEO_SIZE)

  muxer = new Muxer({
    target: new ArrayBufferTarget(),
    video: {
      codec: CODEC,
      width: canvas.width,
      height: canvas.height,
    },
    fastStart: 'in-memory',
    firstTimestampBehavior: 'offset',
  })

  videoEncoder = new VideoEncoder({
    output: (chunk, meta) => muxer.addVideoChunk(chunk, meta),
    error: (e) => console.error(e),
  })
  videoEncoder.configure({
    codec: CODEC_FORMAT,
    width: VIDEO_SIZE.x,
    height: VIDEO_SIZE.y,
    bitrate: BITRATE,
  })

  // Recording state
  startTime = document.timeline.currentTime
  _isRecording = true
  lastKeyFrame = -Infinity
  framesGenerated = 0

  console.log('Recording started.')

  encodeVideoFrame()
  intervalId = setInterval(encodeVideoFrame, 1e3 / FRAME_RATE)
}

export async function stopAndDownload() {
  console.log('Recording stopping...')

  _isRecording = false
  clearInterval(intervalId)

  await videoEncoder.flush()
  muxer.finalize()

  const buffer = muxer.target.buffer

  download(buffer)

  videoEncoder = null
  muxer = null
  startTime = 0
  lastKeyFrame = 0

  resetCanvasSize()

  removeOverlay()

  console.log('Recording stopped.')
}

export function isRecording() {
  return _isRecording
}

function encodeVideoFrame() {
  let elapsedTime = document.timeline.currentTime - startTime
  let frame = new VideoFrame(canvas, {
    timestamp: framesGenerated * 1e6 / FRAME_RATE,
    duration: 1e6 / FRAME_RATE,
  })
  framesGenerated++

  // Ensure keyframes are generated every 5 seconds
  let needsKeyFrame = elapsedTime - lastKeyFrame >= 5000
  if (needsKeyFrame) {
    lastKeyFrame = elapsedTime
  }

  videoEncoder.encode(frame, { keyFrame: needsKeyFrame })
  frame.close()
}

async function download(buffer) {
  const blob = new Blob([buffer], { type: 'video/mp4' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = 'flyby.mp4'
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

function addOverlay(trailData, canvasSize) {
  onyx.setScreenLogo(
    onyx.MAIN_VIEWPORT_ID,
    1,
    canvasSize.x / 2,
    canvasSize.y * 0.1
  )

  const labelPoints = new onyx.Vector2IntList()
  labelPoints.push_back({ x: canvasSize.x / 2, y: canvasSize.y * 0.1 + 100 })

  onyx.addLabelDefaultStyle(
    onyx.MAIN_VIEWPORT_ID,
    'flyby1',
    trailData,
    labelPoints,
    onyx.Styling_SymbolPlacement.POINT
  )

  labelPoints.delete()
}

function removeOverlay() {
  onyx.setScreenLogo(onyx.MAIN_VIEWPORT_ID, -1, 0, 0)

  onyx.clearLabels(onyx.MAIN_VIEWPORT_ID, 'flyby1')
}

function setCanvasToSize(canvasSize) {
  initialCanvas.style.width = canvas.style.width
  initialCanvas.style.height = canvas.style.height
  initialCanvas.style.display = canvas.style.display
  initialCanvas.style.marginLeft = canvas.style['margin-left']
  initialCanvas.style.marginRight = canvas.style['margin-right']
  initialCanvas.size = { x: canvas.width, y: canvas.height }

  if (canvasSize.x > 0 && canvasSize.y > 0) {
    canvas.width = canvasSize.x
    canvas.height = canvasSize.y
    canvas.style.height = '100vh'
    canvas.style.width = `calc(100vh * ${canvasSize.x} / ${canvasSize.y})`
    canvas.style.display = 'block'
    canvas.style['margin-left'] = 'auto'
    canvas.style['margin-right'] = 'auto'

    onyx.setScreenSize(canvasSize)
  }
}

function resetCanvasSize() {
  canvas.width = initialCanvas.size.x
  canvas.height = initialCanvas.size.y
  canvas.style.width = initialCanvas.style.width
  canvas.style.height = initialCanvas.style.height
  canvas.style.display = initialCanvas.style.display
  canvas.style['margin-left'] = initialCanvas.style.marginLeft
  canvas.style['margin-right'] = initialCanvas.style.marginRight

  onyx.setScreenSize(initialCanvas.size)
}
