import { Layer } from './Layer.js';

export class IntersectableLayer extends Layer {
  _intersectLayer = null

  constructor(onyx, layerConfig, intersectLayer) {
    console.assert(intersectLayer)
    super(onyx, layerConfig)
    this._intersectLayer = intersectLayer
    this._intersectLayer.register(this)
  }

  toggle(show) {
    if (!this._intersectLayer.isActive()) {
      super.toggle(show)
    }
  }
}
