import * as marketing from '../utilities/marketing.js'

class SearchComponent extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <style>
      .terrain-analysis-search-container {
        position: absolute;
        top: 10px;
        left: 490px;
        width: 380px;
        height: 36px;
        border-radius: 24px;
        background: white;
        z-index:20;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      }

      @media (max-width: 1200px) {
        .terrain-analysis-search-container {
          top: 60px;
          right: 10px;
          left: unset;
        }
      }
      
      .terrain-analysis-search-container.collapsed {
        top: 10px;
        left: 102px;
      }

      .search {
        position:absolute;
        top:10px;
        left:520px;
        z-index:100;
      }

      .search-icon {
        position:relative;
        display: inline-block;
        font-size:1.1em;
        width: 30px;
        height: 40px;
        top:4px;
        left:8px;
        background:rgba(0, 0, 0, 0);
        color:#666;
      }

      .search-input {
        position:relative;
        display: inline-block;
        top:4px;
      }

      .search-input > input {
        border:none;
        outline-width: 0;
      }

      .float-right {
        position: absolute;
        right: 0px;
      }

      </style>
      <div class="terrain-analysis-search-container">
        <span class="search-icon"><i class="fa fa-search"></i></span>
        <span class="search-input"><input id='search-input' style='width:300px;' type="search" placeholder="Lat/Long Eg: 45.6770, -111.0429" aria-label="Search" value="45.6770, -111.0429"/></span>
      </div>
      `

    document
      .getElementById('search-input')
      .addEventListener('keyup', (event) => {
        console.log(event.key)
        if (event.key === 'Enter') {
          const input = document.getElementById('search-input').value
          const inputValues = input.split(',')
          const lat = parseFloat(inputValues[0])
          const lng = parseFloat(inputValues[1])

          let resultCount = 0

          if (lat && lng && lat !== NaN && lng !== NaN) {
            document.getElementById('search-input').blur()
            const myEvent = new CustomEvent('on-go-to-coordinates', {
              detail: { latitude: lat, longitude: lng },
            })
            document.dispatchEvent(myEvent)

            resultCount = 1
          }

          marketing.logEvent('search_performed', {
            search_type: 'terrain-x',
            query: input,
            result_count: resultCount,
          })
        }
      })
  }
}

customElements.define('search-component', SearchComponent)
