import dateFormat from 'dateformat'
import markupService from '../../services/markup-service.js'
import appService from '../../services/app-service.js'
import { getViewshedState } from '../terrain-tools/viewshed-button-component.js'
import * as marketing from '../../utilities/marketing.js'
import router from '../app-component.js'

class WaypointCreateCardComponent extends HTMLElement {
  constructor() {
    super()
  }
  getApp() {
    return appService.getApp()
  }
  getAppClass() {
    return appService.appClass()
  }
  connectedCallback() {
    this.innerHTML = String.raw`

    <style>
        .disclaimer {
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            margin: 15px 0 30px;
        }

        #waypoint-name-input {
            margin-bottom: 15px;
            width: 100%;
            padding: 5px 10px;
            font-size: 24px;
            font-family: 'Atlas Grotesk', sans-serif;
            border: 2px solid #bdbdbd;
            border-radius: 5px;
            color: #8b8b8b;
        }

        #waypoint-name-input:focus {
            outline: none;
            box-shadow: 0 0 3px #8b8b8b;
        }

        .action-buttons {
            display: flex;
            justify-content: space-between;
        }

        #cancel-waypoint-button {
            margin-right: 12px;
        }
    </style>

    <div class="x-card" id="waypoint-create-card">
        <i class="fa fa-close close"></i>
        <input type="text" id="waypoint-name-input" name="waypoint-name" maxlength="255" placeholder="Waypoint Name"></input>
        <p class="disclaimer">Terrain-X is currently in beta. You will be able to view, edit, and remove this waypoint back in onX Webmap. </p>
        <div class="action-buttons">
            <div class="x-button secondary-light fill-width ${this.getAppClass()}" id="cancel-waypoint-button">Cancel</div>
            <div class="x-button primary fill-width" id="save-waypoint-button">Save</div>
        </div>
    </div>
    
    `

    const saveButton = document.getElementById('save-waypoint-button')
    const cancelButton = document.getElementById('cancel-waypoint-button')
    const nameInput = document.getElementById('waypoint-name-input')
    const closeX = document.querySelector('#waypoint-create-card .close')
    closeX.onclick = () => {
      cancelWaypoint()
    }
    saveButton.onclick = () => {
      saveWaypoint()
    }
    cancelButton.onclick = () => {
      cancelWaypoint()
    }

    // set the default name in the text input placeholder
    nameInput.placeholder = `Terrain-X ${dateFormat(
      Date.now(),
      'mm/dd/yy'
    )} ...`

    /**
     * Save waypoint to backend
     */
    const saveWaypoint = async () => {
      const waypoint = markupService.getFocusedMarkup()

      // grab waypoint name from text input or set to createdAt date
      const defaultName = `Terrain-X ${dateFormat(
        new Date(waypoint.created_at),
        'mm/dd/yy HH:mm'
      )}`
      const name = nameInput.value || defaultName

      // update name of waypoint
      waypoint.name = name
      markupService.updateWaypoint(waypoint.uuid, waypoint)

      // make request to save waypoint to backend
      await markupService.requestCreateWaypoint(waypoint)

      markupService.clearFocusedMarkup()

      closeCard()

      marketing.logEvent('waypoint_create_saved', {
        origin: 'terrain-x',
        waypoint_icon: 'Location',
        photo_count: 0,
        notes_populated: false,
        viewshed_state: getViewshedState(),
        custom_name: name !== defaultName,
        markup_uuid: waypoint.uuid,
      })
    }

    /**
     * Cancel waypoint and remove from map
     */
    const cancelWaypoint = () => {
      const waypoint = markupService.getFocusedMarkup()

      const myEvent = new CustomEvent('on-waypoint-remove', {
        detail: { uuid: waypoint.uuid },
      })
      document.dispatchEvent(myEvent)

      markupService.clearFocusedMarkup()

      closeCard()

      marketing.logEvent('waypoint_create_discarded', {
        viewshed_state: getViewshedState(),
        discard_prompt: false,
        origin: 'terrain-x',
      })
    }

    /**
     * Close the card
     */
    const closeCard = () => {
      nameInput.value = ''

      router.navigate(`/${this.getApp()}`)
    }
  }
}

customElements.define(
  'waypoint-create-card-component',
  WaypointCreateCardComponent
)
