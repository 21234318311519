import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

export function initialize() {
  let env = 'development'
  if (/labs.onxmaps.com/.test(window.location.href)) {
    env = 'production'
  }

  Sentry.init({
    dsn: 'https://6b4c87073de64b7b854a9214fbbad451@o240024.ingest.sentry.io/4504459964907520',
    integrations: [new BrowserTracing()],
    sampleRate: 0.01,
    environment: env,
  })
}
