export const TOKEN_KEY = 'onxjwt'

/**
 * Sets the onxjwt user token in local storage
 */
export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token)
}

/**
 * Removes the onxjwt user token from local storage
 */
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
}

/**
 * Retrieves the encoded user token from local storage
 * @returns {String} encoded user token
 */
export function retrieveToken() {
  const token = localStorage.getItem(TOKEN_KEY)
  return token || ''
}
